.text-container-whattobring {
    p{
        color: black;

    }
}
#productTitle {
    color: black !important;
}
.card-box {
    //width: 190px;
    //height: 254px;
    height: fit-content;
    border-radius: 30px;
    background: #cccccc;
    box-shadow: 15px 15px 30px rgb(202, 202, 202),
                -15px -15px 30px rgb(173, 173, 173);
                padding: 1rem 1rem 0.5rem 1rem;
                margin: 0 0 1rem 0 !important;
                h3 {
                    margin: 0.3rem;
                }
   }
.form-form {
    width: 93%;
    //padding: 1rem;
    height: fit-content;
    background-image: linear-gradient(to bottom, #dddddd,#c9c9c9);
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 0.5rem;
    backdrop-filter: blur(30px);
  background-color: rgba(187, 185, 185, 0.5);
  padding: 1rem;
  p,input,textarea {
    color: black;
  }

  }

.imgbarrasw {
    display: none;
}
.imgbarrasb {
    display: block;
}
.dark-mode {
    .imgbarrasb {
        display: none;
    }
    .imgbarrasw {
        display: block;
    }
    #productTitle {
        color: white !important;
    }
    .text-container-whattobring {
        p{
            color: white;
    
        }
    }
    .card-box {
        //width: 190px;
        //height: 254px;
        height: fit-content;
        border-radius: 30px;
        background: #212121;
        box-shadow: 15px 15px 30px rgb(25, 25, 25),
                    -15px -15px 30px rgb(40, 40, 40);
                    padding: 1rem 1rem 0.5rem 1rem;
                    margin: 0 0 1rem 0;
                    h3 {
                        margin: 0.3rem;
                    }
       }
    .form-form {
        width: 93%;
        //padding: 1rem;
        height: fit-content;
        background-image: linear-gradient(to bottom, #2b2b2b,#212121);
        display: flex;
        align-items: center;
        flex-direction: column;
        border-radius: 0.5rem;
        backdrop-filter: blur(30px);
      background-color: rgba(65,65,65,.5);
      padding: 1rem;
      }



    .btn,.btn-yellow-block,.btn-purple,.btn-green,.btn-yellow,.btn-green-block {
        color: $white;
        &:hover {
            color: $white;
        }
    }
    .alerta {
        background-color: #484848 !important;
    }
    footer .bottom{
        background: #1111117a;
        p {

            //line-height: 0.2;
        }
        .vargasdev{
            font-size: 1.2rem;
        }
        a {
            color: #ffe600ef;
        }
      }
    footer .content .right input[type="text"] {
        color: #ffffff;
        background-color: #292929;
      }
      footer .content .topic{
        
        color: $white;
        
      
      }
      footer .content p{
        color: $white;
      }
    footer .content{
        background: #1111117a;   
    }
    .form-popup {
  
        background-color: #222222;
        .form-container {
            background-color: #2c2b2b;
            h1,p,label {
                color: white;
            }
            input {
                background-color: #575656;
            }
        }
    }
    
        //const darkmode = window.matchMedia('(prefers-color-scheme: dark)');
    
        background-color: darken($darkgray, 10);
    p,label,input,select,button,span,textarea {
        color: $white;
    }
    a,h1,h2,h3,h3,h4 {
        color: $white;
    }
    .ad-box card-box,
    .resume-propery {
        background-color: $darkgray;
        border: 1px solid $darkgray;
        .price {
            color: $green;
        }
        .characteristic {
            filter: invert(100%)
        }
    }
    
    .text-about blockquote {
        color: $white;
    }

    .form {
        label,legend,p {
            color: $gray;
        }
        input:not([type="submit"]),textarea,select {
            background-color: $darkgray;
            border: 1px solid $darkgray;
            color: $white;
        }
    }
}