/** Media Queries **/
@mixin telefono {
    @media (min-width: #{$mobile}) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$tablet}) {
        @content;
    }
}
  
@mixin desktop {
    @media (min-width: #{$desktop}) {
        @content;
    }
}
@mixin grid($columns, $space ) {
    display: grid;
    gap: $space;
    grid-template-columns: repeat($columns, 1fr);
}
@mixin btn($bc:$purple, $display:block){
    
    color: $white;
    font-weight: 700;
    text-decoration: none;
    padding: 1rem 3rem;
    text-align: center;
    margin-top: calc( $separation / 2 );
    display: $display;


    
    font-size: 18px;
    
    background: $bc;
    outline: none;
    border-radius: 5px;
    letter-spacing: 1px;
    cursor: pointer;
    
    border: 2px solid $bc;
    transition: all 0.3s ease-in-out;
    &:hover {
        //background: none;
        color:  $white;
        font-size: 17px;
    }
    
}
.ad-content {
    button {
        width: 95%;
        margin-left: -moz-available;
    }
}

