
.right {
    z-index: 99;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    @include tablet {
        align-items: flex-end;
        background-color: transparent;
    }
    .btn-dark-mode {
        width: 3rem;
        margin-top: 3rem;
        margin-bottom: 2rem;
        margin-right: 1rem;
        &:hover {
            cursor: pointer;
        }
        filter: invert(100%);
        @include tablet {
            margin-top: 0;
        }
    }
}
.bar {
    width: 100%;
    height: 12rem;
    

    //background: rgb(2,0,36);
    //background: linear-gradient(180deg, rgba(2,0,36,0.7525209913066789) 0%, rgba(9,9,121,0.6516806551722252) 50%, rgba(9,9,121,0) 100%);
    
    
    display: flex;
    //position: fixed;
    flex-direction: column;
    align-items: center;
    padding-top: 0;
    @include tablet {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    img {
        width: 30rem;
    }
    .imgLogo {
  
        max-width: 10rem;
        margin-left: 0rem;
        @include tablet {
            margin-left: 2.5rem;
        }
    }
}
.navegation {
    visibility: hidden;
    opacity: 0;
    height: 0;
    transition: opacity 0.3s linear, visibility 0.3s ease-in-out, height .5s linear;
    
    @include tablet {
        display: flex;
        align-items: center;
        visibility: visible;
        opacity: 1;
    }
  .dropdown {
    height: 40px !important;
  }
  button {
    height: 100%;
  }
    a,
    .dropbtn {
        color: $white;
        text-decoration: none;
        font-size: 2rem;
        font-weight: 700;
        display: block;
        text-align: center;
        background: rgb(2,0,36);
        background: radial-gradient(circle, rgba(2,0,36,0.9) 0%, rgba(45,0,168,0.7) 50%, rgba(45,0,168,0) 100%); border: none;
        min-width: 15rem;
        padding: 2px 5px !important;
        cursor: pointer;
        
        @include tablet {
            margin-right: 2rem;
            background-color: transparent;
            padding: 0;
            min-width: 0rem;
            &:last-of-type {
                margin-right: 1rem;
            }
            &:hover {
                background-color: transparent;
                font-weight: 700;
                font-size: 2rem;
            }
        }

    }
}
.mobile-menu {
    @include tablet {
        display: none;
    }
    img {
        width: 3rem;
    }
}
.shown {
    visibility: visible;
    opacity: 1;
    height: auto;
}


//-------------------------------------------------------------------

  
.dropdown {
    position: relative;
    display: inline-block;
  }

.dropdown-content {
    display: none;
    position: absolute;
    background-color: rgba(33, 0, 122, 0.9);
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
   
  }

  .dropdown-content a {
    min-width: 15rem;
    padding: 14px 7px;
    margin: 0 !important;
    margin-top: 1px;

  }
  
  .navegation a:hover {
    color: rgb(209, 195, 0) !important;
    font-weight: 700;
    font-size: 2rem;
  }
  
 
  
  .show {display: block;}