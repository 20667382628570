.language {
    color: #000000;
    position: absolute;
    float: right;
    top: 0px;
    right: 2px;
    font-size: 28px;
    z-index: 11;
    img {
      max-width: 40px;
    }
    .lang{
        cursor: pointer;
        opacity: 0.7;
        &:hover{
            opacity: 1;
        }
    }
    @include tablet {img {
      max-width: 40px;
    }}
    @include desktop {img {
      max-width: 25px;
    }}
    .active {
        opacity: 1 !important;
        cursor: initial;
        @include tablet {font-size: 26px;}
        @include desktop {font-size: 19px;}
      }
      button {
        background: transparent;
        border: none;
      }
  }
  