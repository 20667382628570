.captcha-container {
    display: flex;
    justify-content: center;
  }
  
  .captcha {
    display: flex;
    align-items: center;
    height: 100%;
  }
  
  .captcha-text {
    font-size: 16px;
  }
  
  .captcha-box {
    position: relative;
    margin-left: 10px;
    width: 30px;
    height: 30px;
  }
  
  .captcha-box input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  .captcha-box label {
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 30px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .captcha-box input[type="checkbox"]:checked + label::after {
    text-align: center;
    content: "X";
    position: absolute;
    left: 25%;
    top: 0;
    color: #38c172;
    font-size: 18px;
    font-weight: bold;
    
  }