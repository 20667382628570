
.faq-container {
  div {
    background-color: rgba($color: #000000, $alpha: 0.1);
    margin-bottom: 2px;
  }
  summary {
    margin-bottom: 2px;
  }
}
.container h1{
   
    text-align: center;
  }
  
  details{
    background-color: #303030;
    color: #fff;
    font-size: 1.5rem;
  }
  
  summary {
    padding: .5em 1.3rem;
    list-style: none;
    display: flex;
    justify-content: space-between;  
    transition: height 1s ease;
  }
  
  summary::-webkit-details-marker {
    display: none;
  }
  
  summary:after{
    content: "\002B";
  }
  
  details[open] summary {
      border-bottom: 1px solid #aaa;
      margin-bottom: .5em;
  }
  
  details[open] summary:after{
    content: "\00D7";
  }
  
  details[open] div{
    padding: .5em 1em;
  }