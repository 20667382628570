@include desktop {
    .ads-container {
        @include grid(3, 2rem);
    }
    .ads-container-market {
        @include grid(2, 2rem);
    }
    .ads-container-market-1 {
        @include grid(1, 2rem);
    }
}
@include tablet {
    .ads-container {
        @include grid(3, 2rem);
    }
    .ads-container-market {
        @include grid(2, 2rem);
    }
    .ads-container-market-1 {
        @include grid(1, 2rem);
    }
}

.ad-box card-box {
    
    
    background-color: lighten($gray, 5);
    border: 1px solid darken($gray, 5);
    margin-bottom: 2rem;
    
    .ad-content {
        padding: 2rem;
    }
    h3, p {
        margin: 0;
        
    }
    h3 {
        //color: rgb(20, 20, 20) !important; 
        font-weight: 400 !important; 
    }
    
    .price {
        color: $green;
        font-weight: $bold;
        font-size: 3rem;
        text-align: center;
    }
    .location {
        font-size: 2rem;
        text-align: center;
    }
    .iconproduct {
        vertical-align: middle;
        font-size: 2.5rem;
        
    }
}

.icon-characteristic {
    display: flex;
    list-style: none;
    padding: 0;
    li {
        flex: 1;
        display: flex;
        justify-content: center;
        img {
            flex: 0 0 3rem;
            margin-right: 0.7rem;
        }
        p {
            font-weight: $black;
            margin-right: 6rem;
            &:last-of-type {
                margin-right: 0;
            }
        }
        
        }
}
.product-container {
    position: relative;
    display: inline-block;
    .product-item-floating-name {

    }
    .product-item-floating-price {
        position: absolute;
        top: 0%;
        left: 0%;
        //transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.9);
        padding: 0rem 0.5rem;
        border-radius: 0 0 8px 0;
        //text-align: center;
    
    }
}
.location,.price {
    display: flex;
    align-items: center;
    justify-content: center;
}
.whattobring {
    .text-container-whattobring {
        display: flex;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.1);
        margin-bottom: 0.3rem;
        padding: 0.5rem;
        border-radius: 3rem;
        justify-content: center;
    }
    .material-symbols-outlined {
        font-size: 30px;
        margin: 0 1rem;
    }
    p {
        font-size: 17px;
    }
}


.btn-floating {
    
}


.btn-floating:hover {
    background-color: #db8f00; /* Cambia el color de fondo cuando el ratón pasa sobre el botón */
}


.btn-style:link,
.btn-style:visited {
 text-transform: uppercase;
 text-decoration: none;
 color: rgb(244, 244, 244);
 padding: 10px 30px;
 border: 1px solid;
 border-radius: 1000px;
 //display: inline-block;
 transition: all .2s;
 //position: relative;
}

.btn-style:hover {
 transform: translateY(-5px);
 box-shadow: 0 10px 20px rgba(27, 27, 27, .5);
}

.btn-style:active {
 transform: translateY(-3px);
}

.btn-style::after {
 content: "";
 display: inline-block;
 height: 100%;
 width: 100%;
 border-radius: 100px;
 top: 0;
 left: 0;
 position: absolute;
 z-index: -1;
 transition: all .3s;
}

.btn-style:hover::after {
 background-color: #db8f00;
 transform: scaleX(1.4) scaleY(1.5);
 opacity: 0;
}


.btn-floating {
    position: fixed;
    bottom: 20px;  /* Puedes ajustar esto según tu preferencia */
    left: 10px;   /* Puedes ajustar esto según tu preferencia */
    z-index: 1000; /* Asegúrate de que el z-index sea mayor que otros elementos si es necesario */
    padding: 7px 7px;
    background-color: #21007a;/* Color de fondo del botón */
    color: white;           /* Color del texto del botón */
    text-decoration: none;
    border-radius: 5px;     /* Ajusta la esquina redondeada según tu preferencia */
    padding: 0.5rem 2rem !important;
}