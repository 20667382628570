@include tablet {
    .section-blog {
        display: grid;
        grid-template-columns: 2fr 1fr;
        column-gap: 2rem;
    }
}
.blog {
    article {
        //border-top: 4px solid $purple;
        &:first-of-type {
            //border: none;
        }
        img {
            margin-top: 2rem;
        }
    }
}
.input-blog {
    margin-bottom: 2rem;
    background-color: rgba($color: #bebebe, $alpha: 0.2);
    padding: 1rem;
    border-radius: 0.5rem;
    @include tablet {
        display: grid;
        grid-template-columns: 1fr 2fr;
        column-gap: 2rem;
    }
    &:last-of-type {
        margin-bottom: 0;
    }
    
}
.information-meta {

    span {
        color: $yellow;
        font-weight: 300;
    }
}
.text-input {
    a {
        color: $black;
        margin-top: 0rem;
        display: block;
        @include tablet {
            margin-top: 0;
        }
    }
    h4,h3 {
        text-align: left;
        margin: 0;
        line-height: 1.4;
        &:after {
            content:'';
            display: block;
            width: 15rem;
            height: .5rem;
            background-color: $green;
        }
    }
    
    
}
.resume-propery {
    padding: 2rem;
}
