@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900");
html {
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

/* Sections
   ========================================================================== */
/**
 * Remove the margin in all browsers.
 */
body {
  margin: 0;
}

/**
 * Render the `main` element consistently in IE.
 */
main {
  display: block;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
   ========================================================================== */
/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* Text-level semantics
   ========================================================================== */
/**
 * Remove the gray background on active links in IE 10.
 */
a {
  background-color: transparent;
}

/**
 * 1. Remove the bottom border in Chrome 57-
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
   ========================================================================== */
/**
 * Remove the border on images inside links in IE 10.
 */
img {
  border-style: none;
}

/* Forms
   ========================================================================== */
/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input { /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select { /* 1 */
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset por the previous rule.
 */
button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  vertical-align: baseline;
}

/**
 * Remove the default vertical scrollbar in IE 10+.
 */
textarea {
  overflow: auto;
}

/**
 * 1. Add the correct box sizing in IE 10.
 * 2. Remove the padding in IE 10.
 */
[type=checkbox],
[type=radio] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/* Interactive
   ========================================================================== */
/*
 * Add the correct display in Edge, IE 10+, and Firefox.
 */
details {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/* Misc
   ========================================================================== */
/**
 * Add the correct display in IE 10+.
 */
template {
  display: none;
}

/**
 * Add the correct display in IE 10.
 */
[hidden] {
  display: none;
}

/** Media Queries **/
.ad-content button {
  width: 95%;
  margin-left: -moz-available;
}

html {
  font-size: 62.5%;
  box-sizing: border-box;
}

html, body {
  width: 100%;
  overflow-x: hidden;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  font-family: "Lato", sans-serif;
  font-size: 1.6rem;
  line-height: 1.8;
}

p, a {
  color: #000000;
  font-size: 1.7rem;
}

.container {
  width: 95%;
  margin: 0 auto;
}

a {
  text-decoration: none;
  cursor: pointer;
}

img,
picture {
  width: 100%;
  display: block;
}

h1, h2, h3 {
  font-family: "Lato", sans-serif;
  margin: 2.5rem 0;
  font-weight: 300;
  text-align: center;
}

h1 {
  font-size: 3.8rem;
}

h2 {
  font-size: 3.4rem;
}

h3 {
  font-size: 3rem;
}

h4 {
  font-size: 2.6rem;
}

iframe {
  height: 50%;
  width: 100%;
}
@media (min-width: 1024px) {
  iframe {
    height: 100%;
    width: 100%;
  }
}

.header-iframe {
  width: 100%;
  min-height: 1200px;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialsymbolsoutlined/v92/kJF1BvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oDMzByHX9rA6RzaxHMPdY43zj-jCxv3fzvRNU22ZXGJpEpjC_1n-q_4MrImHCIJIZrDCvHOej.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: "liga";
  -moz-osx-font-smoothing: grayscale;
}

.hide {
  display: none;
}

.flag-icon-background {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: 1.33333333em;
  line-height: 1em;
}

.flag-icon:before {
  content: " ";
}

.flag-icon.flag-icon-squared {
  width: 1em;
}

.flag-icon-ad {
  background-image: url(../flags/4x3/ad.svg);
}

.flag-icon-ad.flag-icon-squared {
  background-image: url(../flags/1x1/ad.svg);
}

.flag-icon-ae {
  background-image: url(../flags/4x3/ae.svg);
}

.flag-icon-ae.flag-icon-squared {
  background-image: url(../flags/1x1/ae.svg);
}

.flag-icon-af {
  background-image: url(../flags/4x3/af.svg);
}

.flag-icon-af.flag-icon-squared {
  background-image: url(../flags/1x1/af.svg);
}

.flag-icon-ag {
  background-image: url(../flags/4x3/ag.svg);
}

.flag-icon-ag.flag-icon-squared {
  background-image: url(../flags/1x1/ag.svg);
}

.flag-icon-ai {
  background-image: url(../flags/4x3/ai.svg);
}

.flag-icon-ai.flag-icon-squared {
  background-image: url(../flags/1x1/ai.svg);
}

.flag-icon-al {
  background-image: url(../flags/4x3/al.svg);
}

.flag-icon-al.flag-icon-squared {
  background-image: url(../flags/1x1/al.svg);
}

.flag-icon-am {
  background-image: url(../flags/4x3/am.svg);
}

.flag-icon-am.flag-icon-squared {
  background-image: url(../flags/1x1/am.svg);
}

.flag-icon-ao {
  background-image: url(../flags/4x3/ao.svg);
}

.flag-icon-ao.flag-icon-squared {
  background-image: url(../flags/1x1/ao.svg);
}

.flag-icon-aq {
  background-image: url(../flags/4x3/aq.svg);
}

.flag-icon-aq.flag-icon-squared {
  background-image: url(../flags/1x1/aq.svg);
}

.flag-icon-ar {
  background-image: url(../flags/4x3/ar.svg);
}

.flag-icon-ar.flag-icon-squared {
  background-image: url(../flags/1x1/ar.svg);
}

.flag-icon-as {
  background-image: url(../flags/4x3/as.svg);
}

.flag-icon-as.flag-icon-squared {
  background-image: url(../flags/1x1/as.svg);
}

.flag-icon-at {
  background-image: url(../flags/4x3/at.svg);
}

.flag-icon-at.flag-icon-squared {
  background-image: url(../flags/1x1/at.svg);
}

.flag-icon-au {
  background-image: url(../flags/4x3/au.svg);
}

.flag-icon-au.flag-icon-squared {
  background-image: url(../flags/1x1/au.svg);
}

.flag-icon-aw {
  background-image: url(../flags/4x3/aw.svg);
}

.flag-icon-aw.flag-icon-squared {
  background-image: url(../flags/1x1/aw.svg);
}

.flag-icon-ax {
  background-image: url(../flags/4x3/ax.svg);
}

.flag-icon-ax.flag-icon-squared {
  background-image: url(../flags/1x1/ax.svg);
}

.flag-icon-az {
  background-image: url(../flags/4x3/az.svg);
}

.flag-icon-az.flag-icon-squared {
  background-image: url(../flags/1x1/az.svg);
}

.flag-icon-ba {
  background-image: url(../flags/4x3/ba.svg);
}

.flag-icon-ba.flag-icon-squared {
  background-image: url(../flags/1x1/ba.svg);
}

.flag-icon-bb {
  background-image: url(../flags/4x3/bb.svg);
}

.flag-icon-bb.flag-icon-squared {
  background-image: url(../flags/1x1/bb.svg);
}

.flag-icon-bd {
  background-image: url(../flags/4x3/bd.svg);
}

.flag-icon-bd.flag-icon-squared {
  background-image: url(../flags/1x1/bd.svg);
}

.flag-icon-be {
  background-image: url(../flags/4x3/be.svg);
}

.flag-icon-be.flag-icon-squared {
  background-image: url(../flags/1x1/be.svg);
}

.flag-icon-bf {
  background-image: url(../flags/4x3/bf.svg);
}

.flag-icon-bf.flag-icon-squared {
  background-image: url(../flags/1x1/bf.svg);
}

.flag-icon-bg {
  background-image: url(../flags/4x3/bg.svg);
}

.flag-icon-bg.flag-icon-squared {
  background-image: url(../flags/1x1/bg.svg);
}

.flag-icon-bh {
  background-image: url(../flags/4x3/bh.svg);
}

.flag-icon-bh.flag-icon-squared {
  background-image: url(../flags/1x1/bh.svg);
}

.flag-icon-bi {
  background-image: url(../flags/4x3/bi.svg);
}

.flag-icon-bi.flag-icon-squared {
  background-image: url(../flags/1x1/bi.svg);
}

.flag-icon-bj {
  background-image: url(../flags/4x3/bj.svg);
}

.flag-icon-bj.flag-icon-squared {
  background-image: url(../flags/1x1/bj.svg);
}

.flag-icon-bl {
  background-image: url(../flags/4x3/bl.svg);
}

.flag-icon-bl.flag-icon-squared {
  background-image: url(../flags/1x1/bl.svg);
}

.flag-icon-bm {
  background-image: url(../flags/4x3/bm.svg);
}

.flag-icon-bm.flag-icon-squared {
  background-image: url(../flags/1x1/bm.svg);
}

.flag-icon-bn {
  background-image: url(../flags/4x3/bn.svg);
}

.flag-icon-bn.flag-icon-squared {
  background-image: url(../flags/1x1/bn.svg);
}

.flag-icon-bo {
  background-image: url(../flags/4x3/bo.svg);
}

.flag-icon-bo.flag-icon-squared {
  background-image: url(../flags/1x1/bo.svg);
}

.flag-icon-bq {
  background-image: url(../flags/4x3/bq.svg);
}

.flag-icon-bq.flag-icon-squared {
  background-image: url(../flags/1x1/bq.svg);
}

.flag-icon-br {
  background-image: url(../flags/4x3/br.svg);
}

.flag-icon-br.flag-icon-squared {
  background-image: url(../flags/1x1/br.svg);
}

.flag-icon-bs {
  background-image: url(../flags/4x3/bs.svg);
}

.flag-icon-bs.flag-icon-squared {
  background-image: url(../flags/1x1/bs.svg);
}

.flag-icon-bt {
  background-image: url(../flags/4x3/bt.svg);
}

.flag-icon-bt.flag-icon-squared {
  background-image: url(../flags/1x1/bt.svg);
}

.flag-icon-bv {
  background-image: url(../flags/4x3/bv.svg);
}

.flag-icon-bv.flag-icon-squared {
  background-image: url(../flags/1x1/bv.svg);
}

.flag-icon-bw {
  background-image: url(../flags/4x3/bw.svg);
}

.flag-icon-bw.flag-icon-squared {
  background-image: url(../flags/1x1/bw.svg);
}

.flag-icon-by {
  background-image: url(../flags/4x3/by.svg);
}

.flag-icon-by.flag-icon-squared {
  background-image: url(../flags/1x1/by.svg);
}

.flag-icon-bz {
  background-image: url(../flags/4x3/bz.svg);
}

.flag-icon-bz.flag-icon-squared {
  background-image: url(../flags/1x1/bz.svg);
}

.flag-icon-ca {
  background-image: url(../flags/4x3/ca.svg);
}

.flag-icon-ca.flag-icon-squared {
  background-image: url(../flags/1x1/ca.svg);
}

.flag-icon-cc {
  background-image: url(../flags/4x3/cc.svg);
}

.flag-icon-cc.flag-icon-squared {
  background-image: url(../flags/1x1/cc.svg);
}

.flag-icon-cd {
  background-image: url(../flags/4x3/cd.svg);
}

.flag-icon-cd.flag-icon-squared {
  background-image: url(../flags/1x1/cd.svg);
}

.flag-icon-cf {
  background-image: url(../flags/4x3/cf.svg);
}

.flag-icon-cf.flag-icon-squared {
  background-image: url(../flags/1x1/cf.svg);
}

.flag-icon-cg {
  background-image: url(../flags/4x3/cg.svg);
}

.flag-icon-cg.flag-icon-squared {
  background-image: url(../flags/1x1/cg.svg);
}

.flag-icon-ch {
  background-image: url(../flags/4x3/ch.svg);
}

.flag-icon-ch.flag-icon-squared {
  background-image: url(../flags/1x1/ch.svg);
}

.flag-icon-ci {
  background-image: url(../flags/4x3/ci.svg);
}

.flag-icon-ci.flag-icon-squared {
  background-image: url(../flags/1x1/ci.svg);
}

.flag-icon-ck {
  background-image: url(../flags/4x3/ck.svg);
}

.flag-icon-ck.flag-icon-squared {
  background-image: url(../flags/1x1/ck.svg);
}

.flag-icon-cl {
  background-image: url(../flags/4x3/cl.svg);
}

.flag-icon-cl.flag-icon-squared {
  background-image: url(../flags/1x1/cl.svg);
}

.flag-icon-cm {
  background-image: url(../flags/4x3/cm.svg);
}

.flag-icon-cm.flag-icon-squared {
  background-image: url(../flags/1x1/cm.svg);
}

.flag-icon-cn {
  background-image: url(../flags/4x3/cn.svg);
}

.flag-icon-cn.flag-icon-squared {
  background-image: url(../flags/1x1/cn.svg);
}

.flag-icon-co {
  background-image: url(../flags/4x3/co.svg);
}

.flag-icon-co.flag-icon-squared {
  background-image: url(../flags/1x1/co.svg);
}

.flag-icon-cr {
  background-image: url(../flags/4x3/cr.svg);
}

.flag-icon-cr.flag-icon-squared {
  background-image: url(../flags/1x1/cr.svg);
}

.flag-icon-cu {
  background-image: url(../flags/4x3/cu.svg);
}

.flag-icon-cu.flag-icon-squared {
  background-image: url(../flags/1x1/cu.svg);
}

.flag-icon-cv {
  background-image: url(../flags/4x3/cv.svg);
}

.flag-icon-cv.flag-icon-squared {
  background-image: url(../flags/1x1/cv.svg);
}

.flag-icon-cw {
  background-image: url(../flags/4x3/cw.svg);
}

.flag-icon-cw.flag-icon-squared {
  background-image: url(../flags/1x1/cw.svg);
}

.flag-icon-cx {
  background-image: url(../flags/4x3/cx.svg);
}

.flag-icon-cx.flag-icon-squared {
  background-image: url(../flags/1x1/cx.svg);
}

.flag-icon-cy {
  background-image: url(../flags/4x3/cy.svg);
}

.flag-icon-cy.flag-icon-squared {
  background-image: url(../flags/1x1/cy.svg);
}

.flag-icon-cz {
  background-image: url(../flags/4x3/cz.svg);
}

.flag-icon-cz.flag-icon-squared {
  background-image: url(../flags/1x1/cz.svg);
}

.flag-icon-de {
  background-image: url(../flags/4x3/de.svg);
}

.flag-icon-de.flag-icon-squared {
  background-image: url(../flags/1x1/de.svg);
}

.flag-icon-dj {
  background-image: url(../flags/4x3/dj.svg);
}

.flag-icon-dj.flag-icon-squared {
  background-image: url(../flags/1x1/dj.svg);
}

.flag-icon-dk {
  background-image: url(../flags/4x3/dk.svg);
}

.flag-icon-dk.flag-icon-squared {
  background-image: url(../flags/1x1/dk.svg);
}

.flag-icon-dm {
  background-image: url(../flags/4x3/dm.svg);
}

.flag-icon-dm.flag-icon-squared {
  background-image: url(../flags/1x1/dm.svg);
}

.flag-icon-do {
  background-image: url(../flags/4x3/do.svg);
}

.flag-icon-do.flag-icon-squared {
  background-image: url(../flags/1x1/do.svg);
}

.flag-icon-dz {
  background-image: url(../flags/4x3/dz.svg);
}

.flag-icon-dz.flag-icon-squared {
  background-image: url(../flags/1x1/dz.svg);
}

.flag-icon-ec {
  background-image: url(../flags/4x3/ec.svg);
}

.flag-icon-ec.flag-icon-squared {
  background-image: url(../flags/1x1/ec.svg);
}

.flag-icon-ee {
  background-image: url(../flags/4x3/ee.svg);
}

.flag-icon-ee.flag-icon-squared {
  background-image: url(../flags/1x1/ee.svg);
}

.flag-icon-eg {
  background-image: url(../flags/4x3/eg.svg);
}

.flag-icon-eg.flag-icon-squared {
  background-image: url(../flags/1x1/eg.svg);
}

.flag-icon-eh {
  background-image: url(../flags/4x3/eh.svg);
}

.flag-icon-eh.flag-icon-squared {
  background-image: url(../flags/1x1/eh.svg);
}

.flag-icon-er {
  background-image: url(../flags/4x3/er.svg);
}

.flag-icon-er.flag-icon-squared {
  background-image: url(../flags/1x1/er.svg);
}

.flag-icon-es {
  background-image: url(../flags/4x3/es.svg);
}

.flag-icon-es.flag-icon-squared {
  background-image: url(../flags/1x1/es.svg);
}

.flag-icon-et {
  background-image: url(../flags/4x3/et.svg);
}

.flag-icon-et.flag-icon-squared {
  background-image: url(../flags/1x1/et.svg);
}

.flag-icon-fi {
  background-image: url(../flags/4x3/fi.svg);
}

.flag-icon-fi.flag-icon-squared {
  background-image: url(../flags/1x1/fi.svg);
}

.flag-icon-fj {
  background-image: url(../flags/4x3/fj.svg);
}

.flag-icon-fj.flag-icon-squared {
  background-image: url(../flags/1x1/fj.svg);
}

.flag-icon-fk {
  background-image: url(../flags/4x3/fk.svg);
}

.flag-icon-fk.flag-icon-squared {
  background-image: url(../flags/1x1/fk.svg);
}

.flag-icon-fm {
  background-image: url(../flags/4x3/fm.svg);
}

.flag-icon-fm.flag-icon-squared {
  background-image: url(../flags/1x1/fm.svg);
}

.flag-icon-fo {
  background-image: url(../flags/4x3/fo.svg);
}

.flag-icon-fo.flag-icon-squared {
  background-image: url(../flags/1x1/fo.svg);
}

.flag-icon-fr {
  background-image: url(../flags/4x3/fr.svg);
}

.flag-icon-fr.flag-icon-squared {
  background-image: url(../flags/1x1/fr.svg);
}

.flag-icon-ga {
  background-image: url(../flags/4x3/ga.svg);
}

.flag-icon-ga.flag-icon-squared {
  background-image: url(../flags/1x1/ga.svg);
}

.flag-icon-gb {
  background-image: url(../flags/4x3/gb.svg);
}

.flag-icon-gb.flag-icon-squared {
  background-image: url(../flags/1x1/gb.svg);
}

.flag-icon-gd {
  background-image: url(../flags/4x3/gd.svg);
}

.flag-icon-gd.flag-icon-squared {
  background-image: url(../flags/1x1/gd.svg);
}

.flag-icon-ge {
  background-image: url(../flags/4x3/ge.svg);
}

.flag-icon-ge.flag-icon-squared {
  background-image: url(../flags/1x1/ge.svg);
}

.flag-icon-gf {
  background-image: url(../flags/4x3/gf.svg);
}

.flag-icon-gf.flag-icon-squared {
  background-image: url(../flags/1x1/gf.svg);
}

.flag-icon-gg {
  background-image: url(../flags/4x3/gg.svg);
}

.flag-icon-gg.flag-icon-squared {
  background-image: url(../flags/1x1/gg.svg);
}

.flag-icon-gh {
  background-image: url(../flags/4x3/gh.svg);
}

.flag-icon-gh.flag-icon-squared {
  background-image: url(../flags/1x1/gh.svg);
}

.flag-icon-gi {
  background-image: url(../flags/4x3/gi.svg);
}

.flag-icon-gi.flag-icon-squared {
  background-image: url(../flags/1x1/gi.svg);
}

.flag-icon-gl {
  background-image: url(../flags/4x3/gl.svg);
}

.flag-icon-gl.flag-icon-squared {
  background-image: url(../flags/1x1/gl.svg);
}

.flag-icon-gm {
  background-image: url(../flags/4x3/gm.svg);
}

.flag-icon-gm.flag-icon-squared {
  background-image: url(../flags/1x1/gm.svg);
}

.flag-icon-gn {
  background-image: url(../flags/4x3/gn.svg);
}

.flag-icon-gn.flag-icon-squared {
  background-image: url(../flags/1x1/gn.svg);
}

.flag-icon-gp {
  background-image: url(../flags/4x3/gp.svg);
}

.flag-icon-gp.flag-icon-squared {
  background-image: url(../flags/1x1/gp.svg);
}

.flag-icon-gq {
  background-image: url(../flags/4x3/gq.svg);
}

.flag-icon-gq.flag-icon-squared {
  background-image: url(../flags/1x1/gq.svg);
}

.flag-icon-gr {
  background-image: url(../flags/4x3/gr.svg);
}

.flag-icon-gr.flag-icon-squared {
  background-image: url(../flags/1x1/gr.svg);
}

.flag-icon-gs {
  background-image: url(../flags/4x3/gs.svg);
}

.flag-icon-gs.flag-icon-squared {
  background-image: url(../flags/1x1/gs.svg);
}

.flag-icon-gt {
  background-image: url(../flags/4x3/gt.svg);
}

.flag-icon-gt.flag-icon-squared {
  background-image: url(../flags/1x1/gt.svg);
}

.flag-icon-gu {
  background-image: url(../flags/4x3/gu.svg);
}

.flag-icon-gu.flag-icon-squared {
  background-image: url(../flags/1x1/gu.svg);
}

.flag-icon-gw {
  background-image: url(../flags/4x3/gw.svg);
}

.flag-icon-gw.flag-icon-squared {
  background-image: url(../flags/1x1/gw.svg);
}

.flag-icon-gy {
  background-image: url(../flags/4x3/gy.svg);
}

.flag-icon-gy.flag-icon-squared {
  background-image: url(../flags/1x1/gy.svg);
}

.flag-icon-hk {
  background-image: url(../flags/4x3/hk.svg);
}

.flag-icon-hk.flag-icon-squared {
  background-image: url(../flags/1x1/hk.svg);
}

.flag-icon-hm {
  background-image: url(../flags/4x3/hm.svg);
}

.flag-icon-hm.flag-icon-squared {
  background-image: url(../flags/1x1/hm.svg);
}

.flag-icon-hn {
  background-image: url(../flags/4x3/hn.svg);
}

.flag-icon-hn.flag-icon-squared {
  background-image: url(../flags/1x1/hn.svg);
}

.flag-icon-hr {
  background-image: url(../flags/4x3/hr.svg);
}

.flag-icon-hr.flag-icon-squared {
  background-image: url(../flags/1x1/hr.svg);
}

.flag-icon-ht {
  background-image: url(../flags/4x3/ht.svg);
}

.flag-icon-ht.flag-icon-squared {
  background-image: url(../flags/1x1/ht.svg);
}

.flag-icon-hu {
  background-image: url(../flags/4x3/hu.svg);
}

.flag-icon-hu.flag-icon-squared {
  background-image: url(../flags/1x1/hu.svg);
}

.flag-icon-id {
  background-image: url(../flags/4x3/id.svg);
}

.flag-icon-id.flag-icon-squared {
  background-image: url(../flags/1x1/id.svg);
}

.flag-icon-ie {
  background-image: url(../flags/4x3/ie.svg);
}

.flag-icon-ie.flag-icon-squared {
  background-image: url(../flags/1x1/ie.svg);
}

.flag-icon-il {
  background-image: url(../flags/4x3/il.svg);
}

.flag-icon-il.flag-icon-squared {
  background-image: url(../flags/1x1/il.svg);
}

.flag-icon-im {
  background-image: url(../flags/4x3/im.svg);
}

.flag-icon-im.flag-icon-squared {
  background-image: url(../flags/1x1/im.svg);
}

.flag-icon-in {
  background-image: url(../flags/4x3/in.svg);
}

.flag-icon-in.flag-icon-squared {
  background-image: url(../flags/1x1/in.svg);
}

.flag-icon-io {
  background-image: url(../flags/4x3/io.svg);
}

.flag-icon-io.flag-icon-squared {
  background-image: url(../flags/1x1/io.svg);
}

.flag-icon-iq {
  background-image: url(../flags/4x3/iq.svg);
}

.flag-icon-iq.flag-icon-squared {
  background-image: url(../flags/1x1/iq.svg);
}

.flag-icon-ir {
  background-image: url(../flags/4x3/ir.svg);
}

.flag-icon-ir.flag-icon-squared {
  background-image: url(../flags/1x1/ir.svg);
}

.flag-icon-is {
  background-image: url(../flags/4x3/is.svg);
}

.flag-icon-is.flag-icon-squared {
  background-image: url(../flags/1x1/is.svg);
}

.flag-icon-it {
  background-image: url(../flags/4x3/it.svg);
}

.flag-icon-it.flag-icon-squared {
  background-image: url(../flags/1x1/it.svg);
}

.flag-icon-je {
  background-image: url(../flags/4x3/je.svg);
}

.flag-icon-je.flag-icon-squared {
  background-image: url(../flags/1x1/je.svg);
}

.flag-icon-jm {
  background-image: url(../flags/4x3/jm.svg);
}

.flag-icon-jm.flag-icon-squared {
  background-image: url(../flags/1x1/jm.svg);
}

.flag-icon-jo {
  background-image: url(../flags/4x3/jo.svg);
}

.flag-icon-jo.flag-icon-squared {
  background-image: url(../flags/1x1/jo.svg);
}

.flag-icon-jp {
  background-image: url(../flags/4x3/jp.svg);
}

.flag-icon-jp.flag-icon-squared {
  background-image: url(../flags/1x1/jp.svg);
}

.flag-icon-ke {
  background-image: url(../flags/4x3/ke.svg);
}

.flag-icon-ke.flag-icon-squared {
  background-image: url(../flags/1x1/ke.svg);
}

.flag-icon-kg {
  background-image: url(../flags/4x3/kg.svg);
}

.flag-icon-kg.flag-icon-squared {
  background-image: url(../flags/1x1/kg.svg);
}

.flag-icon-kh {
  background-image: url(../flags/4x3/kh.svg);
}

.flag-icon-kh.flag-icon-squared {
  background-image: url(../flags/1x1/kh.svg);
}

.flag-icon-ki {
  background-image: url(../flags/4x3/ki.svg);
}

.flag-icon-ki.flag-icon-squared {
  background-image: url(../flags/1x1/ki.svg);
}

.flag-icon-km {
  background-image: url(../flags/4x3/km.svg);
}

.flag-icon-km.flag-icon-squared {
  background-image: url(../flags/1x1/km.svg);
}

.flag-icon-kn {
  background-image: url(../flags/4x3/kn.svg);
}

.flag-icon-kn.flag-icon-squared {
  background-image: url(../flags/1x1/kn.svg);
}

.flag-icon-kp {
  background-image: url(../flags/4x3/kp.svg);
}

.flag-icon-kp.flag-icon-squared {
  background-image: url(../flags/1x1/kp.svg);
}

.flag-icon-kr {
  background-image: url(../flags/4x3/kr.svg);
}

.flag-icon-kr.flag-icon-squared {
  background-image: url(../flags/1x1/kr.svg);
}

.flag-icon-kw {
  background-image: url(../flags/4x3/kw.svg);
}

.flag-icon-kw.flag-icon-squared {
  background-image: url(../flags/1x1/kw.svg);
}

.flag-icon-ky {
  background-image: url(../flags/4x3/ky.svg);
}

.flag-icon-ky.flag-icon-squared {
  background-image: url(../flags/1x1/ky.svg);
}

.flag-icon-kz {
  background-image: url(../flags/4x3/kz.svg);
}

.flag-icon-kz.flag-icon-squared {
  background-image: url(../flags/1x1/kz.svg);
}

.flag-icon-la {
  background-image: url(../flags/4x3/la.svg);
}

.flag-icon-la.flag-icon-squared {
  background-image: url(../flags/1x1/la.svg);
}

.flag-icon-lb {
  background-image: url(../flags/4x3/lb.svg);
}

.flag-icon-lb.flag-icon-squared {
  background-image: url(../flags/1x1/lb.svg);
}

.flag-icon-lc {
  background-image: url(../flags/4x3/lc.svg);
}

.flag-icon-lc.flag-icon-squared {
  background-image: url(../flags/1x1/lc.svg);
}

.flag-icon-li {
  background-image: url(../flags/4x3/li.svg);
}

.flag-icon-li.flag-icon-squared {
  background-image: url(../flags/1x1/li.svg);
}

.flag-icon-lk {
  background-image: url(../flags/4x3/lk.svg);
}

.flag-icon-lk.flag-icon-squared {
  background-image: url(../flags/1x1/lk.svg);
}

.flag-icon-lr {
  background-image: url(../flags/4x3/lr.svg);
}

.flag-icon-lr.flag-icon-squared {
  background-image: url(../flags/1x1/lr.svg);
}

.flag-icon-ls {
  background-image: url(../flags/4x3/ls.svg);
}

.flag-icon-ls.flag-icon-squared {
  background-image: url(../flags/1x1/ls.svg);
}

.flag-icon-lt {
  background-image: url(../flags/4x3/lt.svg);
}

.flag-icon-lt.flag-icon-squared {
  background-image: url(../flags/1x1/lt.svg);
}

.flag-icon-lu {
  background-image: url(../flags/4x3/lu.svg);
}

.flag-icon-lu.flag-icon-squared {
  background-image: url(../flags/1x1/lu.svg);
}

.flag-icon-lv {
  background-image: url(../flags/4x3/lv.svg);
}

.flag-icon-lv.flag-icon-squared {
  background-image: url(../flags/1x1/lv.svg);
}

.flag-icon-ly {
  background-image: url(../flags/4x3/ly.svg);
}

.flag-icon-ly.flag-icon-squared {
  background-image: url(../flags/1x1/ly.svg);
}

.flag-icon-ma {
  background-image: url(../flags/4x3/ma.svg);
}

.flag-icon-ma.flag-icon-squared {
  background-image: url(../flags/1x1/ma.svg);
}

.flag-icon-mc {
  background-image: url(../flags/4x3/mc.svg);
}

.flag-icon-mc.flag-icon-squared {
  background-image: url(../flags/1x1/mc.svg);
}

.flag-icon-md {
  background-image: url(../flags/4x3/md.svg);
}

.flag-icon-md.flag-icon-squared {
  background-image: url(../flags/1x1/md.svg);
}

.flag-icon-me {
  background-image: url(../flags/4x3/me.svg);
}

.flag-icon-me.flag-icon-squared {
  background-image: url(../flags/1x1/me.svg);
}

.flag-icon-mf {
  background-image: url(../flags/4x3/mf.svg);
}

.flag-icon-mf.flag-icon-squared {
  background-image: url(../flags/1x1/mf.svg);
}

.flag-icon-mg {
  background-image: url(../flags/4x3/mg.svg);
}

.flag-icon-mg.flag-icon-squared {
  background-image: url(../flags/1x1/mg.svg);
}

.flag-icon-mh {
  background-image: url(../flags/4x3/mh.svg);
}

.flag-icon-mh.flag-icon-squared {
  background-image: url(../flags/1x1/mh.svg);
}

.flag-icon-mk {
  background-image: url(../flags/4x3/mk.svg);
}

.flag-icon-mk.flag-icon-squared {
  background-image: url(../flags/1x1/mk.svg);
}

.flag-icon-ml {
  background-image: url(../flags/4x3/ml.svg);
}

.flag-icon-ml.flag-icon-squared {
  background-image: url(../flags/1x1/ml.svg);
}

.flag-icon-mm {
  background-image: url(../flags/4x3/mm.svg);
}

.flag-icon-mm.flag-icon-squared {
  background-image: url(../flags/1x1/mm.svg);
}

.flag-icon-mn {
  background-image: url(../flags/4x3/mn.svg);
}

.flag-icon-mn.flag-icon-squared {
  background-image: url(../flags/1x1/mn.svg);
}

.flag-icon-mo {
  background-image: url(../flags/4x3/mo.svg);
}

.flag-icon-mo.flag-icon-squared {
  background-image: url(../flags/1x1/mo.svg);
}

.flag-icon-mp {
  background-image: url(../flags/4x3/mp.svg);
}

.flag-icon-mp.flag-icon-squared {
  background-image: url(../flags/1x1/mp.svg);
}

.flag-icon-mq {
  background-image: url(../flags/4x3/mq.svg);
}

.flag-icon-mq.flag-icon-squared {
  background-image: url(../flags/1x1/mq.svg);
}

.flag-icon-mr {
  background-image: url(../flags/4x3/mr.svg);
}

.flag-icon-mr.flag-icon-squared {
  background-image: url(../flags/1x1/mr.svg);
}

.flag-icon-ms {
  background-image: url(../flags/4x3/ms.svg);
}

.flag-icon-ms.flag-icon-squared {
  background-image: url(../flags/1x1/ms.svg);
}

.flag-icon-mt {
  background-image: url(../flags/4x3/mt.svg);
}

.flag-icon-mt.flag-icon-squared {
  background-image: url(../flags/1x1/mt.svg);
}

.flag-icon-mu {
  background-image: url(../flags/4x3/mu.svg);
}

.flag-icon-mu.flag-icon-squared {
  background-image: url(../flags/1x1/mu.svg);
}

.flag-icon-mv {
  background-image: url(../flags/4x3/mv.svg);
}

.flag-icon-mv.flag-icon-squared {
  background-image: url(../flags/1x1/mv.svg);
}

.flag-icon-mw {
  background-image: url(../flags/4x3/mw.svg);
}

.flag-icon-mw.flag-icon-squared {
  background-image: url(../flags/1x1/mw.svg);
}

.flag-icon-mx {
  background-image: url(../flags/4x3/mx.svg);
}

.flag-icon-mx.flag-icon-squared {
  background-image: url(../flags/1x1/mx.svg);
}

.flag-icon-my {
  background-image: url(../flags/4x3/my.svg);
}

.flag-icon-my.flag-icon-squared {
  background-image: url(../flags/1x1/my.svg);
}

.flag-icon-mz {
  background-image: url(../flags/4x3/mz.svg);
}

.flag-icon-mz.flag-icon-squared {
  background-image: url(../flags/1x1/mz.svg);
}

.flag-icon-na {
  background-image: url(../flags/4x3/na.svg);
}

.flag-icon-na.flag-icon-squared {
  background-image: url(../flags/1x1/na.svg);
}

.flag-icon-nc {
  background-image: url(../flags/4x3/nc.svg);
}

.flag-icon-nc.flag-icon-squared {
  background-image: url(../flags/1x1/nc.svg);
}

.flag-icon-ne {
  background-image: url(../flags/4x3/ne.svg);
}

.flag-icon-ne.flag-icon-squared {
  background-image: url(../flags/1x1/ne.svg);
}

.flag-icon-nf {
  background-image: url(../flags/4x3/nf.svg);
}

.flag-icon-nf.flag-icon-squared {
  background-image: url(../flags/1x1/nf.svg);
}

.flag-icon-ng {
  background-image: url(../flags/4x3/ng.svg);
}

.flag-icon-ng.flag-icon-squared {
  background-image: url(../flags/1x1/ng.svg);
}

.flag-icon-ni {
  background-image: url(../flags/4x3/ni.svg);
}

.flag-icon-ni.flag-icon-squared {
  background-image: url(../flags/1x1/ni.svg);
}

.flag-icon-nl {
  background-image: url(../flags/4x3/nl.svg);
}

.flag-icon-nl.flag-icon-squared {
  background-image: url(../flags/1x1/nl.svg);
}

.flag-icon-no {
  background-image: url(../flags/4x3/no.svg);
}

.flag-icon-no.flag-icon-squared {
  background-image: url(../flags/1x1/no.svg);
}

.flag-icon-np {
  background-image: url(../flags/4x3/np.svg);
}

.flag-icon-np.flag-icon-squared {
  background-image: url(../flags/1x1/np.svg);
}

.flag-icon-nr {
  background-image: url(../flags/4x3/nr.svg);
}

.flag-icon-nr.flag-icon-squared {
  background-image: url(../flags/1x1/nr.svg);
}

.flag-icon-nu {
  background-image: url(../flags/4x3/nu.svg);
}

.flag-icon-nu.flag-icon-squared {
  background-image: url(../flags/1x1/nu.svg);
}

.flag-icon-nz {
  background-image: url(../flags/4x3/nz.svg);
}

.flag-icon-nz.flag-icon-squared {
  background-image: url(../flags/1x1/nz.svg);
}

.flag-icon-om {
  background-image: url(../flags/4x3/om.svg);
}

.flag-icon-om.flag-icon-squared {
  background-image: url(../flags/1x1/om.svg);
}

.flag-icon-pa {
  background-image: url(../flags/4x3/pa.svg);
}

.flag-icon-pa.flag-icon-squared {
  background-image: url(../flags/1x1/pa.svg);
}

.flag-icon-pe {
  background-image: url(../flags/4x3/pe.svg);
}

.flag-icon-pe.flag-icon-squared {
  background-image: url(../flags/1x1/pe.svg);
}

.flag-icon-pf {
  background-image: url(../flags/4x3/pf.svg);
}

.flag-icon-pf.flag-icon-squared {
  background-image: url(../flags/1x1/pf.svg);
}

.flag-icon-pg {
  background-image: url(../flags/4x3/pg.svg);
}

.flag-icon-pg.flag-icon-squared {
  background-image: url(../flags/1x1/pg.svg);
}

.flag-icon-ph {
  background-image: url(../flags/4x3/ph.svg);
}

.flag-icon-ph.flag-icon-squared {
  background-image: url(../flags/1x1/ph.svg);
}

.flag-icon-pk {
  background-image: url(../flags/4x3/pk.svg);
}

.flag-icon-pk.flag-icon-squared {
  background-image: url(../flags/1x1/pk.svg);
}

.flag-icon-pl {
  background-image: url(../flags/4x3/pl.svg);
}

.flag-icon-pl.flag-icon-squared {
  background-image: url(../flags/1x1/pl.svg);
}

.flag-icon-pm {
  background-image: url(../flags/4x3/pm.svg);
}

.flag-icon-pm.flag-icon-squared {
  background-image: url(../flags/1x1/pm.svg);
}

.flag-icon-pn {
  background-image: url(../flags/4x3/pn.svg);
}

.flag-icon-pn.flag-icon-squared {
  background-image: url(../flags/1x1/pn.svg);
}

.flag-icon-pr {
  background-image: url(../flags/4x3/pr.svg);
}

.flag-icon-pr.flag-icon-squared {
  background-image: url(../flags/1x1/pr.svg);
}

.flag-icon-ps {
  background-image: url(../flags/4x3/ps.svg);
}

.flag-icon-ps.flag-icon-squared {
  background-image: url(../flags/1x1/ps.svg);
}

.flag-icon-pt {
  background-image: url(../flags/4x3/pt.svg);
}

.flag-icon-pt.flag-icon-squared {
  background-image: url(../flags/1x1/pt.svg);
}

.flag-icon-pw {
  background-image: url(../flags/4x3/pw.svg);
}

.flag-icon-pw.flag-icon-squared {
  background-image: url(../flags/1x1/pw.svg);
}

.flag-icon-py {
  background-image: url(../flags/4x3/py.svg);
}

.flag-icon-py.flag-icon-squared {
  background-image: url(../flags/1x1/py.svg);
}

.flag-icon-qa {
  background-image: url(../flags/4x3/qa.svg);
}

.flag-icon-qa.flag-icon-squared {
  background-image: url(../flags/1x1/qa.svg);
}

.flag-icon-re {
  background-image: url(../flags/4x3/re.svg);
}

.flag-icon-re.flag-icon-squared {
  background-image: url(../flags/1x1/re.svg);
}

.flag-icon-ro {
  background-image: url(../flags/4x3/ro.svg);
}

.flag-icon-ro.flag-icon-squared {
  background-image: url(../flags/1x1/ro.svg);
}

.flag-icon-rs {
  background-image: url(../flags/4x3/rs.svg);
}

.flag-icon-rs.flag-icon-squared {
  background-image: url(../flags/1x1/rs.svg);
}

.flag-icon-ru {
  background-image: url(../flags/4x3/ru.svg);
}

.flag-icon-ru.flag-icon-squared {
  background-image: url(../flags/1x1/ru.svg);
}

.flag-icon-rw {
  background-image: url(../flags/4x3/rw.svg);
}

.flag-icon-rw.flag-icon-squared {
  background-image: url(../flags/1x1/rw.svg);
}

.flag-icon-sa {
  background-image: url(../flags/4x3/sa.svg);
}

.flag-icon-sa.flag-icon-squared {
  background-image: url(../flags/1x1/sa.svg);
}

.flag-icon-sb {
  background-image: url(../flags/4x3/sb.svg);
}

.flag-icon-sb.flag-icon-squared {
  background-image: url(../flags/1x1/sb.svg);
}

.flag-icon-sc {
  background-image: url(../flags/4x3/sc.svg);
}

.flag-icon-sc.flag-icon-squared {
  background-image: url(../flags/1x1/sc.svg);
}

.flag-icon-sd {
  background-image: url(../flags/4x3/sd.svg);
}

.flag-icon-sd.flag-icon-squared {
  background-image: url(../flags/1x1/sd.svg);
}

.flag-icon-se {
  background-image: url(../flags/4x3/se.svg);
}

.flag-icon-se.flag-icon-squared {
  background-image: url(../flags/1x1/se.svg);
}

.flag-icon-sg {
  background-image: url(../flags/4x3/sg.svg);
}

.flag-icon-sg.flag-icon-squared {
  background-image: url(../flags/1x1/sg.svg);
}

.flag-icon-sh {
  background-image: url(../flags/4x3/sh.svg);
}

.flag-icon-sh.flag-icon-squared {
  background-image: url(../flags/1x1/sh.svg);
}

.flag-icon-si {
  background-image: url(../flags/4x3/si.svg);
}

.flag-icon-si.flag-icon-squared {
  background-image: url(../flags/1x1/si.svg);
}

.flag-icon-sj {
  background-image: url(../flags/4x3/sj.svg);
}

.flag-icon-sj.flag-icon-squared {
  background-image: url(../flags/1x1/sj.svg);
}

.flag-icon-sk {
  background-image: url(../flags/4x3/sk.svg);
}

.flag-icon-sk.flag-icon-squared {
  background-image: url(../flags/1x1/sk.svg);
}

.flag-icon-sl {
  background-image: url(../flags/4x3/sl.svg);
}

.flag-icon-sl.flag-icon-squared {
  background-image: url(../flags/1x1/sl.svg);
}

.flag-icon-sm {
  background-image: url(../flags/4x3/sm.svg);
}

.flag-icon-sm.flag-icon-squared {
  background-image: url(../flags/1x1/sm.svg);
}

.flag-icon-sn {
  background-image: url(../flags/4x3/sn.svg);
}

.flag-icon-sn.flag-icon-squared {
  background-image: url(../flags/1x1/sn.svg);
}

.flag-icon-so {
  background-image: url(../flags/4x3/so.svg);
}

.flag-icon-so.flag-icon-squared {
  background-image: url(../flags/1x1/so.svg);
}

.flag-icon-sr {
  background-image: url(../flags/4x3/sr.svg);
}

.flag-icon-sr.flag-icon-squared {
  background-image: url(../flags/1x1/sr.svg);
}

.flag-icon-ss {
  background-image: url(../flags/4x3/ss.svg);
}

.flag-icon-ss.flag-icon-squared {
  background-image: url(../flags/1x1/ss.svg);
}

.flag-icon-st {
  background-image: url(../flags/4x3/st.svg);
}

.flag-icon-st.flag-icon-squared {
  background-image: url(../flags/1x1/st.svg);
}

.flag-icon-sv {
  background-image: url(../flags/4x3/sv.svg);
}

.flag-icon-sv.flag-icon-squared {
  background-image: url(../flags/1x1/sv.svg);
}

.flag-icon-sx {
  background-image: url(../flags/4x3/sx.svg);
}

.flag-icon-sx.flag-icon-squared {
  background-image: url(../flags/1x1/sx.svg);
}

.flag-icon-sy {
  background-image: url(../flags/4x3/sy.svg);
}

.flag-icon-sy.flag-icon-squared {
  background-image: url(../flags/1x1/sy.svg);
}

.flag-icon-sz {
  background-image: url(../flags/4x3/sz.svg);
}

.flag-icon-sz.flag-icon-squared {
  background-image: url(../flags/1x1/sz.svg);
}

.flag-icon-tc {
  background-image: url(../flags/4x3/tc.svg);
}

.flag-icon-tc.flag-icon-squared {
  background-image: url(../flags/1x1/tc.svg);
}

.flag-icon-td {
  background-image: url(../flags/4x3/td.svg);
}

.flag-icon-td.flag-icon-squared {
  background-image: url(../flags/1x1/td.svg);
}

.flag-icon-tf {
  background-image: url(../flags/4x3/tf.svg);
}

.flag-icon-tf.flag-icon-squared {
  background-image: url(../flags/1x1/tf.svg);
}

.flag-icon-tg {
  background-image: url(../flags/4x3/tg.svg);
}

.flag-icon-tg.flag-icon-squared {
  background-image: url(../flags/1x1/tg.svg);
}

.flag-icon-th {
  background-image: url(../flags/4x3/th.svg);
}

.flag-icon-th.flag-icon-squared {
  background-image: url(../flags/1x1/th.svg);
}

.flag-icon-tj {
  background-image: url(../flags/4x3/tj.svg);
}

.flag-icon-tj.flag-icon-squared {
  background-image: url(../flags/1x1/tj.svg);
}

.flag-icon-tk {
  background-image: url(../flags/4x3/tk.svg);
}

.flag-icon-tk.flag-icon-squared {
  background-image: url(../flags/1x1/tk.svg);
}

.flag-icon-tl {
  background-image: url(../flags/4x3/tl.svg);
}

.flag-icon-tl.flag-icon-squared {
  background-image: url(../flags/1x1/tl.svg);
}

.flag-icon-tm {
  background-image: url(../flags/4x3/tm.svg);
}

.flag-icon-tm.flag-icon-squared {
  background-image: url(../flags/1x1/tm.svg);
}

.flag-icon-tn {
  background-image: url(../flags/4x3/tn.svg);
}

.flag-icon-tn.flag-icon-squared {
  background-image: url(../flags/1x1/tn.svg);
}

.flag-icon-to {
  background-image: url(../flags/4x3/to.svg);
}

.flag-icon-to.flag-icon-squared {
  background-image: url(../flags/1x1/to.svg);
}

.flag-icon-tr {
  background-image: url(../flags/4x3/tr.svg);
}

.flag-icon-tr.flag-icon-squared {
  background-image: url(../flags/1x1/tr.svg);
}

.flag-icon-tt {
  background-image: url(../flags/4x3/tt.svg);
}

.flag-icon-tt.flag-icon-squared {
  background-image: url(../flags/1x1/tt.svg);
}

.flag-icon-tv {
  background-image: url(../flags/4x3/tv.svg);
}

.flag-icon-tv.flag-icon-squared {
  background-image: url(../flags/1x1/tv.svg);
}

.flag-icon-tw {
  background-image: url(../flags/4x3/tw.svg);
}

.flag-icon-tw.flag-icon-squared {
  background-image: url(../flags/1x1/tw.svg);
}

.flag-icon-tz {
  background-image: url(../flags/4x3/tz.svg);
}

.flag-icon-tz.flag-icon-squared {
  background-image: url(../flags/1x1/tz.svg);
}

.flag-icon-ua {
  background-image: url(../flags/4x3/ua.svg);
}

.flag-icon-ua.flag-icon-squared {
  background-image: url(../flags/1x1/ua.svg);
}

.flag-icon-ug {
  background-image: url(../flags/4x3/ug.svg);
}

.flag-icon-ug.flag-icon-squared {
  background-image: url(../flags/1x1/ug.svg);
}

.flag-icon-um {
  background-image: url(../flags/4x3/um.svg);
}

.flag-icon-um.flag-icon-squared {
  background-image: url(../flags/1x1/um.svg);
}

.flag-icon-us {
  background-image: url(../flags/4x3/us.svg);
}

.flag-icon-us.flag-icon-squared {
  background-image: url(../flags/1x1/us.svg);
}

.flag-icon-uy {
  background-image: url(../flags/4x3/uy.svg);
}

.flag-icon-uy.flag-icon-squared {
  background-image: url(../flags/1x1/uy.svg);
}

.flag-icon-uz {
  background-image: url(../flags/4x3/uz.svg);
}

.flag-icon-uz.flag-icon-squared {
  background-image: url(../flags/1x1/uz.svg);
}

.flag-icon-va {
  background-image: url(../flags/4x3/va.svg);
}

.flag-icon-va.flag-icon-squared {
  background-image: url(../flags/1x1/va.svg);
}

.flag-icon-vc {
  background-image: url(../flags/4x3/vc.svg);
}

.flag-icon-vc.flag-icon-squared {
  background-image: url(../flags/1x1/vc.svg);
}

.flag-icon-ve {
  background-image: url(../flags/4x3/ve.svg);
}

.flag-icon-ve.flag-icon-squared {
  background-image: url(../flags/1x1/ve.svg);
}

.flag-icon-vg {
  background-image: url(../flags/4x3/vg.svg);
}

.flag-icon-vg.flag-icon-squared {
  background-image: url(../flags/1x1/vg.svg);
}

.flag-icon-vi {
  background-image: url(../flags/4x3/vi.svg);
}

.flag-icon-vi.flag-icon-squared {
  background-image: url(../flags/1x1/vi.svg);
}

.flag-icon-vn {
  background-image: url(../flags/4x3/vn.svg);
}

.flag-icon-vn.flag-icon-squared {
  background-image: url(../flags/1x1/vn.svg);
}

.flag-icon-vu {
  background-image: url(../flags/4x3/vu.svg);
}

.flag-icon-vu.flag-icon-squared {
  background-image: url(../flags/1x1/vu.svg);
}

.flag-icon-wf {
  background-image: url(../flags/4x3/wf.svg);
}

.flag-icon-wf.flag-icon-squared {
  background-image: url(../flags/1x1/wf.svg);
}

.flag-icon-ws {
  background-image: url(../flags/4x3/ws.svg);
}

.flag-icon-ws.flag-icon-squared {
  background-image: url(../flags/1x1/ws.svg);
}

.flag-icon-ye {
  background-image: url(../flags/4x3/ye.svg);
}

.flag-icon-ye.flag-icon-squared {
  background-image: url(../flags/1x1/ye.svg);
}

.flag-icon-yt {
  background-image: url(../flags/4x3/yt.svg);
}

.flag-icon-yt.flag-icon-squared {
  background-image: url(../flags/1x1/yt.svg);
}

.flag-icon-za {
  background-image: url(../flags/4x3/za.svg);
}

.flag-icon-za.flag-icon-squared {
  background-image: url(../flags/1x1/za.svg);
}

.flag-icon-zm {
  background-image: url(../flags/4x3/zm.svg);
}

.flag-icon-zm.flag-icon-squared {
  background-image: url(../flags/1x1/zm.svg);
}

.flag-icon-zw {
  background-image: url(../flags/4x3/zw.svg);
}

.flag-icon-zw.flag-icon-squared {
  background-image: url(../flags/1x1/zw.svg);
}

.flag-icon-eu {
  background-image: url(../flags/4x3/eu.svg);
}

.flag-icon-eu.flag-icon-squared {
  background-image: url(../flags/1x1/eu.svg);
}

.flag-icon-gb-eng {
  background-image: url(../flags/4x3/gb-eng.svg);
}

.flag-icon-gb-eng.flag-icon-squared {
  background-image: url(../flags/1x1/gb-eng.svg);
}

.flag-icon-gb-nir {
  background-image: url(../flags/4x3/gb-nir.svg);
}

.flag-icon-gb-nir.flag-icon-squared {
  background-image: url(../flags/1x1/gb-nir.svg);
}

.flag-icon-gb-sct {
  background-image: url(../flags/4x3/gb-sct.svg);
}

.flag-icon-gb-sct.flag-icon-squared {
  background-image: url(../flags/1x1/gb-sct.svg);
}

.flag-icon-gb-wls {
  background-image: url(../flags/4x3/gb-wls.svg);
}

.flag-icon-gb-wls.flag-icon-squared {
  background-image: url(../flags/1x1/gb-wls.svg);
}

.flag-icon-un {
  background-image: url(../flags/4x3/un.svg);
}

.flag-icon-un.flag-icon-squared {
  background-image: url(../flags/1x1/un.svg);
}

/* fallback */
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialsymbolsoutlined/v119/kJF1BvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oDMzByHX9rA6RzaxHMPdY43zj-jCxv3fzvRNU22ZXGJpEpjC_1n-q_4MrImHCIJIZrDCvHOej.woff2) format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: "liga";
  -moz-osx-font-smoothing: grayscale;
  color: #c47f00 !important;
}

.section {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.content-center {
  max-width: 80rem;
}

.aling-right {
  display: flex;
  justify-content: flex-end;
  text-align: center;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-left {
  margin: auto;
  width: 50%;
}

.center-right {
  margin: auto;
  width: 50%;
}

.text-container-whattobring p {
  color: black;
}

#productTitle {
  color: black !important;
}

.card-box {
  height: fit-content;
  border-radius: 30px;
  background: #cccccc;
  box-shadow: 15px 15px 30px rgb(202, 202, 202), -15px -15px 30px rgb(173, 173, 173);
  padding: 1rem 1rem 0.5rem 1rem;
  margin: 0 0 1rem 0 !important;
}
.card-box h3 {
  margin: 0.3rem;
}

.form-form {
  width: 93%;
  height: fit-content;
  background-image: linear-gradient(to bottom, #dddddd, #c9c9c9);
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 0.5rem;
  backdrop-filter: blur(30px);
  background-color: rgba(187, 185, 185, 0.5);
  padding: 1rem;
}
.form-form p, .form-form input, .form-form textarea {
  color: black;
}

.imgbarrasw {
  display: none;
}

.imgbarrasb {
  display: block;
}

.dark-mode {
  background-color: #1a1a1a;
}
.dark-mode .imgbarrasb {
  display: none;
}
.dark-mode .imgbarrasw {
  display: block;
}
.dark-mode #productTitle {
  color: white !important;
}
.dark-mode .text-container-whattobring p {
  color: white;
}
.dark-mode .card-box {
  height: fit-content;
  border-radius: 30px;
  background: #212121;
  box-shadow: 15px 15px 30px rgb(25, 25, 25), -15px -15px 30px rgb(40, 40, 40);
  padding: 1rem 1rem 0.5rem 1rem;
  margin: 0 0 1rem 0;
}
.dark-mode .card-box h3 {
  margin: 0.3rem;
}
.dark-mode .form-form {
  width: 93%;
  height: fit-content;
  background-image: linear-gradient(to bottom, #2b2b2b, #212121);
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 0.5rem;
  backdrop-filter: blur(30px);
  background-color: rgba(65, 65, 65, 0.5);
  padding: 1rem;
}
.dark-mode .btn, .dark-mode .btn-yellow-block, .dark-mode .btn-purple, .dark-mode .btn-green, .dark-mode .btn-yellow, .dark-mode .btn-green-block {
  color: #FFFFFF;
}
.dark-mode .btn:hover, .dark-mode .btn-yellow-block:hover, .dark-mode .btn-purple:hover, .dark-mode .btn-green:hover, .dark-mode .btn-yellow:hover, .dark-mode .btn-green-block:hover {
  color: #FFFFFF;
}
.dark-mode .alerta {
  background-color: #484848 !important;
}
.dark-mode footer .bottom {
  background: rgba(17, 17, 17, 0.4784313725);
}
.dark-mode footer .bottom .vargasdev {
  font-size: 1.2rem;
}
.dark-mode footer .bottom a {
  color: rgba(255, 230, 0, 0.937254902);
}
.dark-mode footer .content .right input[type=text] {
  color: #ffffff;
  background-color: #292929;
}
.dark-mode footer .content .topic {
  color: #FFFFFF;
}
.dark-mode footer .content p {
  color: #FFFFFF;
}
.dark-mode footer .content {
  background: rgba(17, 17, 17, 0.4784313725);
}
.dark-mode .form-popup {
  background-color: #222222;
}
.dark-mode .form-popup .form-container {
  background-color: #2c2b2b;
}
.dark-mode .form-popup .form-container h1, .dark-mode .form-popup .form-container p, .dark-mode .form-popup .form-container label {
  color: white;
}
.dark-mode .form-popup .form-container input {
  background-color: #575656;
}
.dark-mode p, .dark-mode label, .dark-mode input, .dark-mode select, .dark-mode button, .dark-mode span, .dark-mode textarea {
  color: #FFFFFF;
}
.dark-mode a, .dark-mode h1, .dark-mode h2, .dark-mode h3, .dark-mode h3, .dark-mode h4 {
  color: #FFFFFF;
}
.dark-mode .ad-box card-box,
.dark-mode .resume-propery {
  background-color: #333333;
  border: 1px solid #333333;
}
.dark-mode .ad-box card-box .price,
.dark-mode .resume-propery .price {
  color: rgb(189, 189, 0);
}
.dark-mode .ad-box card-box .characteristic,
.dark-mode .resume-propery .characteristic {
  filter: invert(100%);
}
.dark-mode .text-about blockquote {
  color: #FFFFFF;
}
.dark-mode .form label, .dark-mode .form legend, .dark-mode .form p {
  color: #e1e1e1;
}
.dark-mode .form input:not([type=submit]), .dark-mode .form textarea, .dark-mode .form select {
  background-color: #333333;
  border: 1px solid #333333;
  color: #FFFFFF;
}

.btn-yellow-block {
  color: #FFFFFF;
  font-weight: 700;
  text-decoration: none;
  padding: 1rem 3rem;
  text-align: center;
  margin-top: 2.5rem;
  display: block;
  font-size: 18px;
  background: #c47f00;
  outline: none;
  border-radius: 5px;
  letter-spacing: 1px;
  cursor: pointer;
  border: 2px solid #c47f00;
  transition: all 0.3s ease-in-out;
}
.btn-yellow-block:hover {
  color: #FFFFFF;
  font-size: 17px;
}

.btn-yellow {
  color: #FFFFFF;
  font-weight: 700;
  text-decoration: none;
  padding: 1rem 3rem;
  text-align: center;
  margin-top: 2.5rem;
  display: inline-block;
  font-size: 18px;
  background: #c47f00;
  outline: none;
  border-radius: 5px;
  letter-spacing: 1px;
  cursor: pointer;
  border: 2px solid #c47f00;
  transition: all 0.3s ease-in-out;
}
.btn-yellow:hover {
  color: #FFFFFF;
  font-size: 17px;
}

.btn-green {
  color: #FFFFFF;
  font-weight: 700;
  text-decoration: none;
  padding: 1rem 3rem;
  text-align: center;
  margin-top: 2.5rem;
  display: inline-block;
  font-size: 18px;
  background: rgb(33, 0, 122);
  outline: none;
  border-radius: 5px;
  letter-spacing: 1px;
  cursor: pointer;
  border: 2px solid rgb(33, 0, 122);
  transition: all 0.3s ease-in-out;
}
.btn-green:hover {
  color: #FFFFFF;
  font-size: 17px;
}

.btn-purple {
  color: #FFFFFF;
  font-weight: 700;
  text-decoration: none;
  padding: 1rem 3rem;
  text-align: center;
  margin-top: 2.5rem;
  display: inline-block;
  font-size: 18px;
  background: rgb(33, 0, 122);
  outline: none;
  border-radius: 5px;
  letter-spacing: 1px;
  cursor: pointer;
  border: 2px solid rgb(33, 0, 122);
  transition: all 0.3s ease-in-out;
}
.btn-purple:hover {
  color: #FFFFFF;
  font-size: 17px;
}

.btn-green-block {
  color: #FFFFFF;
  font-weight: 700;
  text-decoration: none;
  padding: 1rem 3rem;
  text-align: center;
  margin-top: 2.5rem;
  display: block;
  font-size: 18px;
  background: rgb(33, 0, 122);
  outline: none;
  border-radius: 5px;
  letter-spacing: 1px;
  cursor: pointer;
  border: 2px solid rgb(33, 0, 122);
  transition: all 0.3s ease-in-out;
}
.btn-green-block:hover {
  color: #FFFFFF;
  font-size: 17px;
}

.btn-purple-block {
  color: #FFFFFF;
  font-weight: 700;
  text-decoration: none;
  padding: 1rem 3rem;
  text-align: center;
  margin-top: 2.5rem;
  display: block;
  font-size: 18px;
  background: rgb(33, 0, 122);
  outline: none;
  border-radius: 5px;
  letter-spacing: 1px;
  cursor: pointer;
  border: 2px solid rgb(33, 0, 122);
  transition: all 0.3s ease-in-out;
}
.btn-purple-block:hover {
  color: #FFFFFF;
  font-size: 17px;
}

.btn-product-block {
  color: #FFFFFF;
  font-weight: 700;
  text-decoration: none;
  padding: 1rem 3rem;
  text-align: center;
  margin-top: 2.5rem;
  display: block;
  font-size: 18px;
  background: #aaa;
  outline: none;
  border-radius: 5px;
  letter-spacing: 1px;
  cursor: pointer;
  border: 2px solid #aaa;
  transition: all 0.3s ease-in-out;
}
.btn-product-block:hover {
  color: #FFFFFF;
  font-size: 17px;
}

.added {
  background-color: #c47f00;
}

/** HOME **/
footer {
  width: 100%;
  position: block;
  bottom: 0;
  left: 0;
}
footer .fab, footer .fas {
  margin-top: 30%;
}

footer .content .topic {
  color: #000000;
}

.right {
  align-items: center !important;
}

footer .content {
  max-width: 1350px;
  margin: auto;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: center;
  background: #f1f0f0;
}

footer .content p, a {
  color: #000000;
}

footer .content .right input[type=text] {
  color: #000000;
  background-color: #f1f0f0;
}

footer .content .box {
  width: 33%;
  transition: all 0.4s ease;
}

footer .content .topic {
  font-size: 22px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 16px;
}

footer .content p {
  text-align: justify;
  color: #000000;
}

footer .content .lower .topic {
  margin: 24px 0 5px 0;
}

footer .content .lower i {
  padding-right: 16px;
}

footer .content .middle {
  padding-left: 80px;
}

footer .content .middle a {
  line-height: 32px;
}

footer .content .right input[type=text] {
  height: 45px;
  width: 100%;
  outline: none;
  border-radius: 5px;
  padding-left: 10px;
  font-size: 17px;
  border: 2px solid rgba(34, 34, 34, 0.3647058824);
}

/*footer .content .right button{
  height: 42px;
  width: 100%;
  font-size: 18px;
  color: #d9d9d9;
  background: rgba(33,0,122,.9);
  outline: none;
  border-radius: 5px;
  letter-spacing: 1px;
  cursor: pointer;
  margin-top: 12px;
  border: 2px solid rgba(33,0,122,.9);
  transition: all 0.3s ease-in-out;
}
.content .right button:hover{
  background: none;
  color:  rgba(33,0,122,.9);
}*/
footer .content .media-icons a {
  font-size: 16px;
  height: 45px;
  width: 45px;
  display: inline-block;
  text-align: center;
  line-height: 43px;
  border-radius: 5px;
  border: 2px solid #222222;
  margin: 30px 5px 0 0;
  transition: all 0.3s ease;
  background-color: rgba(255, 255, 255, 0.1);
}

.content .media-icons a:hover {
  border-color: rgba(33, 0, 122, 0.9);
}

footer .bottom {
  width: 100%;
  text-align: right;
  background: #f1f0f0;
  padding: 0 40px 5px 0;
  display: inline-block;
}

footer .bottom a {
  color: rgb(33, 0, 122);
  font-weight: 700;
}

footer a {
  transition: all 0.3s ease;
}

.middle a:hover {
  color: #c47f00;
  font-weight: 700;
  font-size: 2rem;
}

footer a:hover {
  color: rgb(33, 0, 122);
}

@media (max-width: 1100px) {
  footer .content .middle {
    padding-left: 50px;
  }
}
@media (max-width: 950px) {
  footer .content .box {
    width: 50%;
  }
  .content .right {
    margin-top: 40px;
  }
}
@media (max-width: 560px) {
  footer {
    position: relative;
  }
  footer .content .box {
    width: 100%;
    margin-top: 30px;
  }
  footer .content .middle {
    padding-left: 0;
  }
}
.no-webp .home {
  background-image: url(/build/img/base/content/header-img-2.jpg);
}

.webp .home {
  background-image: url(/build/img/base/content/header-img-2.webp);
}

.header .dropdown-content a:hover {
  transition: all 0.3s ease;
  color: rgba(255, 255, 255, 0.9);
}
.header a:hover {
  transition: all 0.3s ease;
  color: rgb(209, 195, 0);
}
.header .dropbtn:hover {
  transition: all 0.3s ease;
  color: rgb(209, 195, 0);
}
.header.home {
  background-position: center center;
  background-size: cover;
}
@media (min-width: 768px) {
  .header.home {
    min-height: 70rem;
  }
}
@media (min-width: 768px) {
  .header.home .content-header {
    min-height: 70rem;
  }
}

.content-header {
  display: inline-block;
  min-width: 100%;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
}
.content-header h1 {
  text-align: center;
  color: rgb(206, 206, 206);
  font-weight: 700;
  line-height: 1.2;
}
.content-header span {
  color: yellow;
  font-weight: 700;
  font-size: 5rem;
}
.content-header .div-text-start {
  width: 100%;
  text-align: center;
}
@media (min-width: 768px) {
  .content-header .div-text-start {
    padding-top: 15%;
  }
}
.content-header .btn-header {
  border-radius: 1rem;
}
.content-header .dotContainer {
  text-align: center;
  width: 100%;
  bottom: 0;
  margin-top: 5rem;
}
@media (min-width: 768px) {
  .content-header .dotContainer {
    position: absolute;
    margin-top: 0;
  }
}
.content-header .dot {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}
.content-header .active {
  background-color: #3d3d3d;
}

.no-bg {
  background-color: rgba(0, 0, 0, 0);
}

.right {
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 768px) {
  .right {
    align-items: flex-end;
    background-color: transparent;
  }
}
.right .btn-dark-mode {
  width: 3rem;
  margin-top: 3rem;
  margin-bottom: 2rem;
  margin-right: 1rem;
  filter: invert(100%);
}
.right .btn-dark-mode:hover {
  cursor: pointer;
}
@media (min-width: 768px) {
  .right .btn-dark-mode {
    margin-top: 0;
  }
}

.bar {
  width: 100%;
  height: 12rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0;
}
@media (min-width: 768px) {
  .bar {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
.bar img {
  width: 30rem;
}
.bar .imgLogo {
  max-width: 10rem;
  margin-left: 0rem;
}
@media (min-width: 768px) {
  .bar .imgLogo {
    margin-left: 2.5rem;
  }
}

.navegation {
  visibility: hidden;
  opacity: 0;
  height: 0;
  transition: opacity 0.3s linear, visibility 0.3s ease-in-out, height 0.5s linear;
}
@media (min-width: 768px) {
  .navegation {
    display: flex;
    align-items: center;
    visibility: visible;
    opacity: 1;
  }
}
.navegation .dropdown {
  height: 40px !important;
}
.navegation button {
  height: 100%;
}
.navegation a,
.navegation .dropbtn {
  color: #FFFFFF;
  text-decoration: none;
  font-size: 2rem;
  font-weight: 700;
  display: block;
  text-align: center;
  background: rgb(2, 0, 36);
  background: radial-gradient(circle, rgba(2, 0, 36, 0.9) 0%, rgba(45, 0, 168, 0.7) 50%, rgba(45, 0, 168, 0) 100%);
  border: none;
  min-width: 15rem;
  padding: 2px 5px !important;
  cursor: pointer;
}
@media (min-width: 768px) {
  .navegation a,
  .navegation .dropbtn {
    margin-right: 2rem;
    background-color: transparent;
    padding: 0;
    min-width: 0rem;
  }
  .navegation a:last-of-type,
  .navegation .dropbtn:last-of-type {
    margin-right: 1rem;
  }
  .navegation a:hover,
  .navegation .dropbtn:hover {
    background-color: transparent;
    font-weight: 700;
    font-size: 2rem;
  }
}

@media (min-width: 768px) {
  .mobile-menu {
    display: none;
  }
}
.mobile-menu img {
  width: 3rem;
}

.shown {
  visibility: visible;
  opacity: 1;
  height: auto;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: rgba(33, 0, 122, 0.9);
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  min-width: 15rem;
  padding: 14px 7px;
  margin: 0 !important;
  margin-top: 1px;
}

.navegation a:hover {
  color: rgb(209, 195, 0) !important;
  font-weight: 700;
  font-size: 2rem;
}

.show {
  display: block;
}

@media (min-width: 768px) {
  .icons-about {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(2, 1fr);
  }
}

.icon {
  text-align: center;
}
.icon img {
  height: 10rem;
}
.icon h3 {
  text-transform: uppercase;
}

@media (min-width: 1024px) {
  .ads-container {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(3, 1fr);
  }
  .ads-container-market {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(2, 1fr);
  }
  .ads-container-market-1 {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (min-width: 768px) {
  .ads-container {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(3, 1fr);
  }
  .ads-container-market {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(2, 1fr);
  }
  .ads-container-market-1 {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(1, 1fr);
  }
}
.ad-box card-box {
  background-color: #eeeeee;
  border: 1px solid #d4d4d4;
  margin-bottom: 2rem;
}
.ad-box card-box .ad-content {
  padding: 2rem;
}
.ad-box card-box h3, .ad-box card-box p {
  margin: 0;
}
.ad-box card-box h3 {
  font-weight: 400 !important;
}
.ad-box card-box .price {
  color: rgb(189, 189, 0);
  font-weight: 700;
  font-size: 3rem;
  text-align: center;
}
.ad-box card-box .location {
  font-size: 2rem;
  text-align: center;
}
.ad-box card-box .iconproduct {
  vertical-align: middle;
  font-size: 2.5rem;
}

.icon-characteristic {
  display: flex;
  list-style: none;
  padding: 0;
}
.icon-characteristic li {
  flex: 1;
  display: flex;
  justify-content: center;
}
.icon-characteristic li img {
  flex: 0 0 3rem;
  margin-right: 0.7rem;
}
.icon-characteristic li p {
  font-weight: #000000;
  margin-right: 6rem;
}
.icon-characteristic li p:last-of-type {
  margin-right: 0;
}

.product-container {
  position: relative;
  display: inline-block;
}
.product-container .product-item-floating-price {
  position: absolute;
  top: 0%;
  left: 0%;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 0rem 0.5rem;
  border-radius: 0 0 8px 0;
}

.location, .price {
  display: flex;
  align-items: center;
  justify-content: center;
}

.whattobring .text-container-whattobring {
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  margin-bottom: 0.3rem;
  padding: 0.5rem;
  border-radius: 3rem;
  justify-content: center;
}
.whattobring .material-symbols-outlined {
  font-size: 30px;
  margin: 0 1rem;
}
.whattobring p {
  font-size: 17px;
}

.btn-floating:hover {
  background-color: #db8f00; /* Cambia el color de fondo cuando el ratón pasa sobre el botón */
}

.btn-style:link,
.btn-style:visited {
  text-transform: uppercase;
  text-decoration: none;
  color: rgb(244, 244, 244);
  padding: 10px 30px;
  border: 1px solid;
  border-radius: 1000px;
  transition: all 0.2s;
}

.btn-style:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(27, 27, 27, 0.5);
}

.btn-style:active {
  transform: translateY(-3px);
}

.btn-style::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 100px;
  top: 0;
  left: 0;
  position: absolute;
  z-index: -1;
  transition: all 0.3s;
}

.btn-style:hover::after {
  background-color: #db8f00;
  transform: scaleX(1.4) scaleY(1.5);
  opacity: 0;
}

.btn-floating {
  position: fixed;
  bottom: 20px; /* Puedes ajustar esto según tu preferencia */
  left: 10px; /* Puedes ajustar esto según tu preferencia */
  z-index: 1000; /* Asegúrate de que el z-index sea mayor que otros elementos si es necesario */
  padding: 7px 7px;
  background-color: #21007a; /* Color de fondo del botón */
  color: white; /* Color del texto del botón */
  text-decoration: none;
  border-radius: 5px; /* Ajusta la esquina redondeada según tu preferencia */
  padding: 0.5rem 2rem !important;
}

.no-webp .contact-image {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("/build/img/base/content/pozoAzulWaterFall.jpg");
  background-position: center bottom;
}

.webp .contact-image {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("/build/img/base/content/pozoAzulWaterFall.webp");
  background-position: center bottom;
}

.contact-image {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("/build/img/base/content/pozoAzulWaterFall.jpg");
  background-position: center bottom;
  background-size: cover;
  padding: 10rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contact-image h1 {
  font-size: 7rem;
  font-weight: 900;
}
.contact-image p {
  text-align: center;
  font-size: 1.8rem;
  color: #FFFFFF;
  padding: 0 5rem;
}
@media (min-width: 1024px) {
  .contact-image p {
    padding: 0 25rem;
  }
}

.contactbox p {
  line-height: 0;
  margin-bottom: 5rem;
}
.contactbox span, .contactbox a {
  font-size: 2.2rem;
}

textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

.button-container {
  display: flex;
  justify-content: right;
}

.text-about form {
  padding: 1rem;
}

@media (min-width: 768px) {
  .section-blog {
    display: grid;
    grid-template-columns: 2fr 1fr;
    column-gap: 2rem;
  }
}
.blog article img {
  margin-top: 2rem;
}

.input-blog {
  margin-bottom: 2rem;
  background-color: rgba(190, 190, 190, 0.2);
  padding: 1rem;
  border-radius: 0.5rem;
}
@media (min-width: 768px) {
  .input-blog {
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 2rem;
  }
}
.input-blog:last-of-type {
  margin-bottom: 0;
}

.information-meta span {
  color: #c47f00;
  font-weight: 300;
}

.text-input a {
  color: #000000;
  margin-top: 0rem;
  display: block;
}
@media (min-width: 768px) {
  .text-input a {
    margin-top: 0;
  }
}
.text-input h4, .text-input h3 {
  text-align: left;
  margin: 0;
  line-height: 1.4;
}
.text-input h4:after, .text-input h3:after {
  content: "";
  display: block;
  width: 15rem;
  height: 0.5rem;
  background-color: rgb(189, 189, 0);
}

.resume-propery {
  padding: 2rem;
}

.testimonial {
  background-color: rgb(33, 0, 122);
  font-size: 2.4rem;
  padding: 2rem;
  color: #FFFFFF;
  border-radius: 2rem;
}
.testimonial blockquote {
  padding-left: 5rem;
  position: relative;
}
.testimonial blockquote::before {
  content: "";
  background-image: url(/build/img/base/comilla.svg);
  background-repeat: no-repeat;
  width: 6rem;
  height: 8rem;
  display: block;
  position: absolute;
  left: -2rem;
}
.testimonial p {
  text-align: right;
  color: #FFFFFF;
}

.form {
  padding: 0rem;
}
@media (min-width: 1024px) {
  .form {
    padding: 0rem;
    padding-right: 1rem;
  }
}
.form p {
  font-size: 1.4rem;
  color: #333333;
  margin: 0rem 0 0 0;
}
.form legend {
  font-size: 2rem;
  color: #333333;
}
.form label {
  font-weight: 700;
  text-transform: uppercase;
  display: block;
}
.form input:not([type=submit]), .form textarea, .form select {
  padding: 1rem;
  display: block;
  width: 100%;
  background-color: #FFFFFF;
  border: 1px solid #e1e1e1;
  border-radius: 1rem;
  margin-bottom: 0rem;
}
.form textarea {
  height: 20rem;
}
.form .form-contact {
  max-width: 30rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form input[type=radio] {
  margin: 0;
  width: auto;
}

.gallery {
  margin-top: 5rem;
  /* Slideshow container */
  /* Next & previous buttons */
  /* Position the "next button" to the right */
  /* On hover, add a black background color with a little bit see-through */
  /* Caption text */
  /* Number text (1/3 etc) */
  /* The dots/bullets/indicators */
  /* Fading animation */
  /* On smaller screens, decrease text size */
}
.gallery .gmySlides {
  display: none;
}
.gallery .gslideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}
.gallery .gprev, .gallery .gnext {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  line-height: 0.5;
  color: rgb(255, 208, 0);
  font-weight: bold;
  font-size: 5rem;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}
.gallery .gprev {
  left: 0;
}
.gallery .gnext {
  right: 0;
  border-radius: 3px 0 0 3px;
}
.gallery .gprev:hover, .gallery .gnext:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
.gallery .gtext {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}
.gallery .gnumbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}
.gallery .gdot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}
.gallery .gactive, .gallery .gdot:hover {
  background-color: #717171;
}
.gallery .gfade {
  animation-name: fade;
  animation-duration: 1.5s;
}
@keyframes gfade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
@media only screen and (max-width: 300px) {
  .gallery .gprev, .gallery .gnext, .gallery .gtext {
    font-size: 11px;
  }
}

.cart-container-7 {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(1, 1fr);
}

.DESCRIPTIONs {
  display: none;
}

.DESCRIPTIONs .center {
  height: 3rem !important;
}

.product-image {
  max-width: 5rem;
}

.center {
  height: 5rem;
}

.cant {
  padding: 1rem;
}

@media (min-width: 1024px) {
  .cart-container-3 {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(3, 1fr);
  }
  .cart-container-2 {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(2, 1fr);
  }
  .cart-container-1 {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(1, 1fr);
  }
  .cart-container-6 {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(6, 1fr);
  }
  .cart-container-7 {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(7, 1fr);
  }
  .cart-container-2-0 {
    display: grid;
    gap: 0rem;
    grid-template-columns: repeat(2, 1fr);
  }
  .product-image {
    max-width: 10rem;
  }
  .center {
    height: 15rem !important;
  }
  .cant {
    padding: 0rem;
  }
}
.cart select {
  text-align: center;
}
.cart .product {
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0.5rem;
  padding: 1rem;
}
.cart .date {
  max-height: 7rem;
  border-right: 1px solid rgba(202, 202, 202, 0.753) !important;
}
.cart .date,
.cart .stime {
  padding: 1rem !important;
  background-color: rgb(189, 189, 0) !important;
  border: none !important;
  text-align: center !important;
  display: block !important;
}
.cart .date:hover,
.cart .stime:hover {
  background-color: #aeae00 !important;
}
.cart .date {
  max-width: 15rem !important;
}
.cart p, .cart label {
  text-align: center;
  font-size: 2.2rem;
}
.cart .qty,
.cart .product-removal {
  background-color: rgb(189, 189, 0);
  border: none;
  text-align: center;
  display: block;
}
.cart .qty:hover,
.cart .product-removal:hover {
  background-color: #aeae00;
}
.cart .qty,
.cart .product-removal {
  font-size: 2.2rem;
  min-width: 5rem;
}
.cart .product-removal {
  padding: 0.8rem;
}
.cart .total-panel {
  margin: 5rem;
  text-align: center;
}
.cart .totals {
  max-width: 15rem;
}

.shopping-cart {
  position: fixed;
  bottom: 5rem;
  right: 2rem;
  z-index: 999;
}
.shopping-cart span {
  margin-top: 30%;
}

.shopping-cart a {
  display: block;
  width: 50px;
  height: 50px;
  background-color: #007bff;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 50px;
  font-size: 24px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.shopping-cart a:hover {
  transform: scale(1.1);
}

.principalCartFrame {
  display: none;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  width: 100%;
  height: 100%;
}

#paypal-button-container {
  display: flex !important;
  justify-content: center !important;
  text-align: -moz-center !important;
}

.center-paypal-buttons {
  width: 100%;
  text-align: -moz-center;
}
.center-paypal-buttons .buttons-container {
  width: 30rem;
}

.popupbg {
  display: none;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  width: 100%;
  height: 100%;
}
.popupbg button {
  display: block;
  width: 100%;
}
.popupbg p {
  text-transform: uppercase;
}

/* The popup form - hidden por default */
.form-popup {
  background-color: #eee;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 1.5px 1.5px 3px #0e0e0e, -1.5px -1.5px 3px rgba(95, 94, 94, 0.25), inset 0px 0px 0px #0e0e0e, inset 0px 0px 0px #5f5e5e;
  padding: 10px;
  z-index: 100;
  min-width: 85%;
}
@media (min-width: 768px) {
  .form-popup {
    min-width: 55%;
  }
}

/* Add styles to the form container */
.form-container {
  width: 100%;
  padding: 10px;
  background-color: white;
}
.form-container h1 {
  color: black;
  line-height: 1;
  margin-top: 5px;
}
.form-container label,
.form-container .date,
.form-container p {
  color: black;
  text-align: left;
}
.form-container p {
  margin: 0;
  line-height: 1;
}
.form-container .date {
  padding: 1rem;
}
.form-container button {
  width: 100%;
}
.form-container .datelodge-container {
  display: grid;
  gap: 0rem;
  grid-template-columns: repeat(1, 1fr);
}
.form-container input {
  margin-bottom: 4px;
}

/* Agrega estilos para el botón "X" flotante */
.btn-yellow-block-form {
  position: absolute;
  top: 10px; /* Ajusta la distancia desde la parte superior según sea necesario */
  right: 10px; /* Ajusta la distancia desde la derecha según sea necesario */
  background-color: transparent;
  border: none;
  width: auto !important;
  font-size: 2.5rem;
  color: rgb(196, 127, 0) !important;
}

.btn-yellow-block-form:hover {
  font-size: 3rem;
  cursor: pointer;
}

/* Asegúrate de que el contenedor del formulario tenga una posición relativa */
.form-popup {
  position: relative;
}

.faq-container div {
  background-color: rgba(0, 0, 0, 0.1);
  margin-bottom: 2px;
}
.faq-container summary {
  margin-bottom: 2px;
}

.container h1 {
  text-align: center;
}

details {
  background-color: #303030;
  color: #fff;
  font-size: 1.5rem;
}

summary {
  padding: 0.5em 1.3rem;
  list-style: none;
  display: flex;
  justify-content: space-between;
  transition: height 1s ease;
}

summary::-webkit-details-marker {
  display: none;
}

summary:after {
  content: "+";
}

details[open] summary {
  border-bottom: 1px solid #aaa;
  margin-bottom: 0.5em;
}

details[open] summary:after {
  content: "×";
}

details[open] div {
  padding: 0.5em 1em;
}

.alert-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999999;
  transition: visibility 0s, opacity 0.5s;
}
.alert-container .alerta {
  position: relative;
  max-width: 500px;
  width: 90%;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
}
.alert-container .alert-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.alert-container .alert-header h3 {
  margin: 0;
}
.alert-container .close-btn {
  padding: 0;
  font-size: 24px;
  font-weight: bold;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: rgb(255, 102, 102);
}
.alert-container .close-btn:hover,
.alert-container .close-btn:focus {
  color: rgb(252, 29, 29);
}
.alert-container .alert-body p {
  margin: 0;
}

.captcha-container {
  display: flex;
  justify-content: center;
}

.captcha {
  display: flex;
  align-items: center;
  height: 100%;
}

.captcha-text {
  font-size: 16px;
}

.captcha-box {
  position: relative;
  margin-left: 10px;
  width: 30px;
  height: 30px;
}

.captcha-box input[type=checkbox] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.captcha-box label {
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.captcha-box input[type=checkbox]:checked + label::after {
  text-align: center;
  content: "X";
  position: absolute;
  left: 25%;
  top: 0;
  color: #38c172;
  font-size: 18px;
  font-weight: bold;
}

.language {
  color: #000000;
  position: absolute;
  float: right;
  top: 0px;
  right: 2px;
  font-size: 28px;
  z-index: 11;
}
.language img {
  max-width: 40px;
}
.language .lang {
  cursor: pointer;
  opacity: 0.7;
}
.language .lang:hover {
  opacity: 1;
}
@media (min-width: 768px) {
  .language img {
    max-width: 40px;
  }
}
@media (min-width: 1024px) {
  .language img {
    max-width: 25px;
  }
}
.language .active {
  opacity: 1 !important;
  cursor: initial;
}
@media (min-width: 768px) {
  .language .active {
    font-size: 26px;
  }
}
@media (min-width: 1024px) {
  .language .active {
    font-size: 19px;
  }
}
.language button {
  background: transparent;
  border: none;
}

.mySlides {
  overflow: hidden;
}

img {
  vertical-align: middle;
}

/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  position: relative;
  padding: 1.5rem;
}
.slideshow-container img {
  max-height: 35rem;
  height: 35rem;
}
@media (min-width: 768px) {
  .slideshow-container img {
    height: 40rem;
  }
}
.slideshow-container .prev, .slideshow-container .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 6px;
  margin-top: -22px;
  color: rgb(255, 230, 0);
  font-weight: bold;
  font-size: 24px;
  transition: 0.6s ease;
  border-radius: 3px 3px 3px 3px;
  user-select: none;
  background-color: rgba(0, 0, 0, 0.3);
  border: none;
}

.ads-container-market .slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}
.ads-container-market .slideshow-container img {
  max-height: 35rem;
}

/* Next & previous buttons */
/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
  right: 1.5rem !important;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .prev, .next, .text {
    font-size: 11px;
  }
}
/** Internal Pages**/
@media (min-width: 1024px) {
  .content-about {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(2, 1fr);
  }
}

.text-about blockquote {
  font-weight: 900;
  font-size: 2.2rem;
  margin: 3rem 0 0 0;
  padding-left: 1rem 0 3rem 0;
}

.button-sw {
  --hover-shadows: 9px 9px 17px rgba(33, 0, 122,0.5), -9px -9px 17px rgba(38, 1, 141,0.5);
  --accent: fuchsia;
  font-weight: bold;
  letter-spacing: 0.1em;
  border: none;
  border-radius: 1.1em;
  background-color: #2d00a8;
  cursor: pointer;
  color: white;
  transition: box-shadow ease-in-out 0.3s, background-color ease-in-out 0.1s, letter-spacing ease-in-out 0.1s, transform ease-in-out 0.1s;
  box-shadow: 6px 6px 4px rgba(33, 0, 122, 0.2), -6px -6px 4px rgba(38, 1, 141, 0.2);
  margin: 1.2rem 0rem !important;
}

.button-sw:hover {
  box-shadow: var(--hover-shadows);
}

.button-sw:active {
  box-shadow: var(--hover-shadows), var(--accent) 0px 0px 30px 5px;
  background-color: var(--accent);
  transform: scale(0.95);
}

.button-fl {
  --green: #c47f00;
  font-size: 15px;
  padding: 0.9em 2.7em;
  margin: 1.2rem 1rem 0rem 1rem !important;
  letter-spacing: 0.06em;
  position: relative;
  font-family: inherit;
  border-radius: 1.1em;
  overflow: hidden;
  transition: all 0.3s;
  line-height: 1.4em;
  border: 2px solid var(--green);
  background: linear-gradient(to right, rgba(196, 127, 0, 0.1) 1%, transparent 40%, transparent 60%, rgba(196, 127, 0, 0.1) 100%);
  color: var(--green);
  box-shadow: inset 0 0 10px rgba(196, 127, 0, 0.4), 0 0 9px 3px rgba(196, 127, 0, 0.1);
}

.button-fl:hover {
  color: #db8f00;
  box-shadow: inset 0 0 10px rgba(196, 127, 0, 0.6), 0 0 9px 3px rgba(196, 127, 0, 0.2);
}

.button-fl:before {
  content: "";
  position: absolute;
  left: -4em;
  width: 100%;
  height: 100%;
  top: 0;
  transition: transform 0.4s ease-in-out;
  background: linear-gradient(to right, transparent 1%, rgba(196, 127, 0, 0.1) 40%, rgba(196, 127, 0, 0.1) 60%, transparent 100%);
}

.able-button {
  width: 100%;
}

.button-fl:hover:before {
  transform: translateX(17em);
}

.icon-characteristic p {
  display: flex;
  justify-content: center;
  align-items: center;
}

.price {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardContainer-tr {
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-tr {
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10px;
  border-radius: 10px;
  backdrop-filter: blur(30px);
  background-color: rgba(65, 65, 65, 0.308);
  border: 1px solid rgba(255, 255, 255, 0.089);
  margin-bottom: 0.5rem;
  cursor: pointer;
}
.card-tr h3 {
  margin: 0;
}

.cardContainer-tr::before {
  width: 300px;
  height: 300px;
  content: "";
  position: absolute;
  background-color: rgba(33, 0, 122, 0);
  z-index: -1;
  border-radius: 50%;
  left: 100px;
  top: 50px;
  transition: all 1s;
}

.cardContainer-tr:hover::before {
  transform: translate(-50px, 50px);
}

.card-ts {
  border-radius: 50px;
  box-shadow: 5px 5px 10px rgba(33, 0, 122, 0.7), -5px -5px 10px rgba(39, 0, 148, 0.7);
}

::selection {
  background-color: gray;
}

.container-form {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.title-form {
  color: white;
  margin: 0;
  font-size: 2rem;
}

.input-form {
  margin: 0.5rem 0.5rem;
  padding: 1rem 0.5rem;
  width: 100%;
  background-color: inherit;
  color: wheat;
  border: none;
  outline: none;
}

.username-form {
  border-bottom: 1px solid #db8f00;
  transition: all 400ms;
}

.username-form:hover {
  background-color: #424242;
  border: none;
  border-radius: 0.5rem;
}

.password-form {
  border-bottom: 1px solid #db8f00;
  transition: all 400ms;
}

.password-form:hover {
  background-color: #424242;
  border: none;
  border-radius: 0.5rem;
}

.btn-form {
  height: 3rem;
  width: 20rem;
  margin-top: 3rem;
  background-color: rgba(33, 0, 122, 0.7);
  border-radius: 0.5rem;
  border: none;
  font-size: 1.2rem;
  transition: all 400ms;
  background-color: rgba(33, 0, 122, 0.7);
  box-shadow: 0 0 1px rgba(33, 0, 122, 0.5), 0 0 1px rgba(33, 0, 122, 0.5);
}

.btn-form:hover {
  background-color: rgba(33, 0, 122, 0.7);
  box-shadow: 0 0 10px rgba(33, 0, 122, 0.5), 0 0 10px rgba(33, 0, 122, 0.5);
}

.center-ck {
  width: 100%;
  text-align: center;
  display: flex;
}

.input-ck {
  position: relative;
  top: 8px;
  width: 40px;
  height: 40px;
  -webkit-appearance: none;
  outline: none;
  transition: 0.5s;
}

.ads-container-market {
  display: block;
}
@media (min-width: 1024px) {
  .ads-container-market {
    display: flex !important;
  }
}

.input-ck:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #db8f00;
  opacity: 0.8;
  box-sizing: border-box;
  transition: 0.5s;
}

.input-ck:checked:before {
  border-left: none;
  border-top: none;
  width: 20px;
  border-color: #db8f00;
  transform: rotate(45deg) translate(5px, -10px);
}

.text-container-whattobring {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ad-info {
  text-align: left;
  display: block;
}
@media (min-width: 1024px) {
  .ad-info {
    display: flex;
  }
}

.whatsapp-button {
  font-size: 2rem;
}