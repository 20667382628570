.no-webp .home {
    background-image: url(/build/img/base/content/header-img-2.jpg);
    
}
.webp .home {
    background-image: url(/build/img/base/content/header-img-2.webp);
    
}

.header {
    
    
    .dropdown-content {
        a:hover {
        transition: all 0.3s ease;
        color: rgba(255, 255, 255, 0.9);
      }
    }
    a:hover {
        transition: all 0.3s ease;
        color: rgb(209, 195, 0);
      }
    .dropbtn:hover {
        transition: all 0.3s ease;
        color: rgb(209, 195, 0);
      }
    &.home{
        
        background-position: center center;
        background-size: cover;
        @include tablet {
            min-height: 70rem;
        }
        .content-header {
            @include tablet {
                min-height: 70rem;
            }
        }
    }
}

.content-header{
    display: inline-block;
    min-width: 100%;
    align-items: center;
    background-color: rgba($color: #000000, $alpha: 0);
    h1 {
        text-align: center;
        color: rgb(206, 206, 206);
        font-weight: $bold;
        line-height: 1.2;
        //color: $purple;
    }
    span {
        color: yellow;
        font-weight: $bold;
        font-size: 5rem;
    }
    .div-text-start {
        
        width: 100%;
        text-align: center;
        @include tablet {
            padding-top: 15%;
        }
    }
    .btn-header {
        border-radius: 1rem;
    }
    .dotContainer {
        text-align:center;
        width: 100%;
        bottom: 0;
        margin-top: 5rem;
        @include tablet {
            position: absolute;
            margin-top: 0;
        }
        
    }
    
    .dot {
        height: 15px;
        width: 15px;
        margin: 0 2px;
        background-color: #bbb;
        border-radius: 50%;
        display: inline-block;
        transition: background-color 0.6s ease;
        
      }
      .active {
        background-color: #3d3d3d;
      }

}
.no-bg{
    
    background-color: rgba($color: #000000, $alpha: 0);

}

