
.popupbg {
    display: none;
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    z-index: 9999;
    width: 100%;
    height: 100%;
    button {
        
        display: block;
        width: 100%;
    }
    p {
        text-transform: uppercase;
    }
}

  
  /* The popup form - hidden por default */
  .form-popup {
  
    background-color: #eee;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    box-shadow: 1.5px 1.5px 3px #0e0e0e, -1.5px -1.5px 3px rgb(95 94 94 / 25%), inset 0px 0px 0px #0e0e0e, inset 0px -0px 0px #5f5e5e;
    padding: 10px;
    
    z-index: 100;
    
    min-width: 85%;
    @include tablet {
        min-width: 55%;
    }
  }
  
  /* Add styles to the form container */
  .form-container {
    width: 100%;
    padding: 10px;
    
    background-color: white;
    h1 {
        color: black;
        line-height: 1;
        margin-top: 5px;
    }
    label, 
    .date,
    p {
        color: black;
        text-align: left;
        
    }
    p {
        margin: 0;
        line-height: 1;
    }
    .date {padding: 1rem;}
    button {
        width: 100%;
    }
    .datelodge-container {
        @include grid(1, 0rem);
    }
    input {
        margin-bottom: 4px;
    }
    
  }

  /* Agrega estilos para el botón "X" flotante */
.btn-yellow-block-form {
    position: absolute;
    top: 10px; /* Ajusta la distancia desde la parte superior según sea necesario */
    right: 10px; /* Ajusta la distancia desde la derecha según sea necesario */
    background-color: transparent;
    border: none;
    width: auto !important;
    font-size: 2.5rem;
    color: rgb(196, 127, 0) !important;
    
}

.btn-yellow-block-form:hover {
    font-size: 3rem;
    cursor: pointer;
}
/* Asegúrate de que el contenedor del formulario tenga una posición relativa */
.form-popup {
    position: relative;
}
