.alert-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999999;
    
    transition: visibility 0s, opacity 0.5s;
  
  
  .alerta {
    position: relative;
    max-width: 500px;
    width: 90%;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  }
  
  .alert-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .alert-header h3 {
    margin: 0;
  }
  
  .close-btn {
    padding: 0;
    font-size: 24px;
    font-weight: bold;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    color: rgb(255, 102, 102);
  }
  
  .close-btn:hover,
  .close-btn:focus {
    color: rgb(252, 29, 29);
  }
  
  .alert-body p {
    margin: 0;
  }
}