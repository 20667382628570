.gallery {
    margin-top: 5rem;
   
    .gmySlides {display: none}
    
    
    /* Slideshow container */
    .gslideshow-container {
      max-width: 1000px;
      position: relative;
      margin: auto;
    }
    
    /* Next & previous buttons */
    .gprev, .gnext {
      cursor: pointer;
      position: absolute;
      top: 50%;
      width: auto;
      padding: 16px;
      margin-top: -22px;
      line-height: 0.5;
      color: rgb(255, 208, 0);
      font-weight: bold;
      font-size: 5rem;
      transition: 0.6s ease;
      border-radius: 0 3px 3px 0;
      user-select: none;
    }
    .gprev{
      left: 0;
    }
    /* Position the "next button" to the right */
    .gnext {
      right: 0;
      border-radius: 3px 0 0 3px;
    }
    
    /* On hover, add a black background color with a little bit see-through */
    .gprev:hover, .gnext:hover {
      background-color: rgba(0,0,0,0.8);
    }
    
    /* Caption text */
    .gtext {
      color: #f2f2f2;
      font-size: 15px;
      padding: 8px 12px;
      position: absolute;
      bottom: 8px;
      width: 100%;
      text-align: center;
    }
    
    /* Number text (1/3 etc) */
    .gnumbertext {
      color: #f2f2f2;
      font-size: 12px;
      padding: 8px 12px;
      position: absolute;
      top: 0;
    }
    
    /* The dots/bullets/indicators */
    .gdot {
      cursor: pointer;
      height: 15px;
      width: 15px;
      margin: 0 2px;
      background-color: #bbb;
      border-radius: 50%;
      display: inline-block;
      transition: background-color 0.6s ease;
    }
    
    .gactive, .gdot:hover {
      background-color: #717171;
    }
    
    /* Fading animation */
    .gfade {
      animation-name: fade;
      animation-duration: 1.5s;
    }
    
    @keyframes gfade {
      from {opacity: .4} 
      to {opacity: 1}
    }
    
    /* On smaller screens, decrease text size */
    @media only screen and (max-width: 300px) {
      .gprev, .gnext,.gtext {font-size: 11px}
    }
}