.section {
    margin-top: calc($separation / 2);
    margin-bottom: calc($separation / 2);
}
.content-center {
    max-width: 80rem;
}
.aling-right {
    display: flex;
    justify-content: flex-end;
    
    text-align: center;
}
.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    
  }
.center {
    display: flex;
    justify-content: center;
    align-items: center;
  
  
   
  }
  .center-left {
    margin: auto;
    width: 50%;
  }
  .center-right {
    margin: auto;
    width: 50%;
  }
  .principalCartFrame {
    
  }