@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900');

// Fuentes
$font_principal: 'Lato', sans-serif;

// Tamaño de Media Queries
$mobile: 480px;
$tablet: 768px;
$desktop: 1024px;

// Colores
$black: #000000;
$purple: rgb(33, 0, 122);
$yellow: #c47f00;
$green: rgb(189, 189, 0);
$white: #FFFFFF;
$gray: #e1e1e1;
$darkgray: #333333;

$separation: 5rem;

// Fuentes
$slim: 300;
$regular: 400;
$bold: 700;
$boldblack: 900;

$color-border: #eee;
$color-label: #aaa;
$font-default: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-bold: 'HelveticaNeue-Medium', 'Helvetica Neue Medium';
