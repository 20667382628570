.btn-yellow-block {
    @include btn($yellow, block);
    
}
.btn-yellow {
    @include btn($yellow, inline-block);
}
.btn-green {
    @include btn($purple, inline-block);
}
.btn-purple {
    @include btn($purple, inline-block);
}
.btn-green-block {
    @include btn($purple, block);
}
.btn-purple-block {
    @include btn($purple, block);
}
.btn-product-block {
    @include btn($color-label, block);
    
}
.added {
    background-color: $yellow;
}

