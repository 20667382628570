.testimonial {
    background-color: $purple;
    font-size: 2.4rem;
    padding: 2rem;
    color: $white;
    border-radius: 2rem;
    blockquote {
        padding-left: 5rem;
        position: relative;
        &::before{
            content: '';
            background-image: url(/build/img/base/comilla.svg);
            background-repeat: no-repeat;
            width: 6rem;
            height: 8rem;
            display: block;
            position: absolute;
            left: -2rem;
        }
    }
    p {
        text-align: right;
        color: $white;
    }
}