.icons-about {
    @include tablet {
        @include grid(2, 2rem);
    }
    
}
.icon {
    text-align: center;
    img {
        height: 10rem;
    }
    h3 {
        text-transform: uppercase;
    }
}