


   .button-sw {
    --hover-shadows: 9px 9px 17px rgba(33, 0, 122,0.5), -9px -9px 17px rgba(38, 1, 141,0.5);
    --accent: fuchsia;
    font-weight: bold;
    letter-spacing: 0.1em;
    border: none;
    border-radius: 1.1em;
    background-color: #2d00a8;
    cursor: pointer;
    color: white;
    //padding: 1em 2em;
    transition: box-shadow ease-in-out 0.3s, background-color ease-in-out 0.1s,
      letter-spacing ease-in-out 0.1s, transform ease-in-out 0.1s;
    box-shadow: 6px 6px 4px rgba(33, 0, 122,0.2), -6px -6px 4px rgba(38, 1, 141,0.2);
    //padding: 1rem;
    margin: 1.2rem 0rem !important;


    
  }
  
  .button-sw:hover {
    box-shadow: var(--hover-shadows);
  }
  
  .button-sw:active {
    box-shadow: var(--hover-shadows), var(--accent) 0px 0px 30px 5px;
    background-color: var(--accent);
    transform: scale(0.95);
  }





  .button-fl {
    --green: #c47f00;
    font-size: 15px;
    padding: 0.9em 2.7em;
    margin: 1.2rem 1rem 0rem 1rem !important;
    letter-spacing: 0.06em;
    position: relative;
    font-family: inherit;
    border-radius: 1.1em;
    overflow: hidden;
    transition: all 0.3s;
    line-height: 1.4em;
    border: 2px solid var(--green);
    background: linear-gradient(to right, rgba(196, 127, 0, 0.1) 1%, transparent 40%,transparent 60% , rgba(196, 127, 0, 0.1) 100%);
    color: var(--green);
    box-shadow: inset 0 0 10px rgba(196, 127, 0, 0.4), 0 0 9px 3px rgba(196, 127, 0, 0.1);
  }
  
  .button-fl:hover {
    color: #db8f00;
    box-shadow: inset 0 0 10px rgba(196, 127, 0, 0.6), 0 0 9px 3px rgba(196, 127, 0, 0.2);
  }
  
  .button-fl:before {
    content: "";
    position: absolute;
    left: -4em;
    width: 100%;
  height: 100%;
    top: 0;
    transition: transform .4s ease-in-out;
    background: linear-gradient(to right, transparent 1%, rgba(196, 127, 0, 0.1) 40%,rgba(196, 127, 0, 0.1) 60% , transparent 100%);
  }
  .able-button {
    width: 100%;
  }
  .able-button:hover {

  }
  .button-fl:hover:before {
    transform: translateX(17em);
  }

  .icon-characteristic {
    p {
        display: flex;
        justify-content: center;
        align-items: center;
    }
  }
  .price {
    display: flex;
    justify-content: center;
    align-items: center;
}



.cardContainer-tr {
    width: fit-content;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  
  .card-tr {
    height: fit-content;
    position: relative;
    //width: 220px;
    //height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 20px 10px;
    border-radius: 10px;
    backdrop-filter: blur(30px);
    background-color: rgba(65, 65, 65, 0.308);
    border: 1px solid rgba(255, 255, 255, 0.089);
    margin-bottom: 0.5rem;
    cursor: pointer;
    h3 {
        margin: 0;
        //line-height: 1.3;
    }
  }
  
  
  
  
  .cardContainer-tr::before {
    width: 300px;
    height: 300px;
    content: "";
    position: absolute;
    background-color: rgba(33, 0, 122,0);
    z-index: -1;
    border-radius: 50%;
    left: 100px;
    top: 50px;
    transition: all 1s;
  }
  
  .cardContainer-tr:hover::before {
    transform: translate(-50px, 50px);
  }
  


  .card-ts {
   // width: 190px;
  //  height: 254px;
    border-radius: 50px;
   // background: #e0e0e0;
    box-shadow: 5px 5px 10px rgba(33,0,122,.7),
                  -5px -5px 10px rgba(39, 0, 148, 0.7);
   }







   ::selection {
    background-color: gray;
  }
  
  .container-form {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  
  
  
  .title-form {
    color: white;
    margin: 0;
    font-size: 2rem;
  }
  
  .input-form {
    margin: 0.5rem 0.5rem;
    padding: 1rem 0.5rem;
    width: 100%;
    background-color: inherit;
    color: wheat;
    border: none;
    outline: none;
  }
  
  .username-form {
    border-bottom: 1px solid #db8f00;
    transition: all 400ms;
  }
  
  .username-form:hover {
    background-color: #424242;
    border: none;
    border-radius: 0.5rem;
  }
  
  .password-form {
    border-bottom: 1px solid #db8f00;
    transition: all 400ms;
  }
  
  .password-form:hover {
    background-color: #424242;
    border: none;
    border-radius: 0.5rem;
  }
  
  .btn-form {
    height: 3rem;
    width: 20rem;
    margin-top: 3rem;
    background-color: rgba(33,0,122,.7);
    border-radius: 0.5rem;
    border: none;
    font-size: 1.2rem;
    transition: all 400ms;
    background-color: rgba(33,0,122,.7);
    box-shadow: 0 0 1px  rgba(33,0,122,.5), 0 0 1px rgba(33,0,122,.5);
}

.btn-form:hover {
    background-color: rgba(33,0,122,.7);
      box-shadow: 0 0 10px  rgba(33,0,122,.5), 0 0 10px rgba(33,0,122,.5);
    //box-shadow: none;
  }
  



  .center-ck {
    width: 100%;
    text-align: center;
    display: flex;
    //width: 100%;
  }
  
  //input[type="checkbox"] {
  .input-ck {
    position: relative;
    top: 8px;
    width: 40px;
    height: 40px;
    -webkit-appearance: none;
    outline: none;
    transition: 0.5s;
  }
  .ads-container-market {
      display: block;
      @include desktop {
        display: flex !important;
}
  }
  .input-ck:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid #db8f00;
    opacity: 0.8;
    box-sizing: border-box;
    transition: 0.5s;
  }
  
  .input-ck:checked:before {
    border-left: none;
    border-top: none;
    width: 20px;
    border-color: #db8f00;
    transform: rotate(45deg) translate(5px, -10px);
  }

  .cardContainer-tr {
    
  }
  .text-container-whattobring {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ad-info {
    text-align: left;
    display: block;
    @include desktop {
        display: flex;
    }
  }
  .whatsapp-button {
    //width: 70px !important;
    font-size: 2rem;

  }