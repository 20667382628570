
.mySlides {
  overflow: hidden;
  
 }
img {vertical-align: middle;}

/* Slideshow container */

 

.slideshow-container {
  max-width: 1000px;
  position: relative;
  //margin: 0 5rem !important;
  padding: 1.5rem;
  img {
    max-height: 35rem;
    height: 35rem;
    @include tablet {
      height: 40rem;
  }
  }
  .prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 6px;
    margin-top: -22px;
    color: rgba(255, 230, 0, 1);
    font-weight: bold;
    font-size: 24px;
    transition: 0.6s ease;
    border-radius: 3px 3px 3px 3px;
    user-select: none;
    background-color: rgba(0, 0, 0, 0.3);
    border: none;
  }
}
  .ads-container-market {
  .slideshow-container {
    max-width: 1000px;
    position: relative;
    margin: auto;
    img {
      max-height: 35rem;
    }
  }
}
  
/* Next & previous buttons */


/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
  right: 1.5rem !important;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.8);
}


.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .prev, .next,.text {font-size: 11px}
}





