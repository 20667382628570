.form {
    
    padding: 0rem;

    @include desktop {
        padding: 0rem;
        padding-right: 1rem;
    }
    p {
        font-size: 1.4rem;
        color: $darkgray;
        margin: 0rem 0 0 0;
    }
    legend {
        font-size: 2rem;
        color: $darkgray;
    }
    label {
        font-weight: $bold;
        text-transform: uppercase;
        display: block;
    }
    input:not([type="submit"]), textarea, select {
        padding: 1rem;
        display: block;
        width: 100%;
        background-color: $white;
        border: 1px solid $gray;
        border-radius: 1rem;
        margin-bottom: 0rem;
    }
    
    textarea {
        height: 20rem;
    }
    .form-contact {
        max-width: 30rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    input[type="radio"] {
        margin: 0;
        width: auto;
    }
}
