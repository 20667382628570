.content-about {
    @include desktop {
        @include grid(2, 2rem);
    }
}
.text-about blockquote {
    font-weight: $boldblack;
    font-size: 2.2rem;
    margin: 3rem 0 0 0;
    padding-left: 1rem 0 3rem 0;

}