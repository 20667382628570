.no-webp .contact-image {
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('/build/img/base/content/pozoAzulWaterFall.jpg');
  background-position:center bottom;
}
.webp .contact-image {
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('/build/img/base/content/pozoAzulWaterFall.webp');
  background-position:center bottom;
}
.contact-image {
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('/build/img/base/content/pozoAzulWaterFall.jpg');
  background-position:center bottom;
    background-size: cover;
    padding: calc( $separation * 2 ) 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
        font-size: 7rem;
        font-weight: $boldblack;
        
    }
    p {
      text-align: center;
        font-size: 1.8rem;
        color: $white;
        padding: 0 5rem;
        @include desktop {
          padding: 0 25rem;
      }
    }
    
}
.contactbox {
  p {
    line-height: 0;
    margin-bottom: 5rem;
  }
  span,a {
    font-size: 2.2rem;
  }
}




 textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
  }
  
  
  
  
  
  .button-container {
    display:flex;
    justify-content:right;
  }
  .text-about form {
    padding: 1rem;
  }
