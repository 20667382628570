

.cart-container-7 {
    @include grid(1, 1rem);
}
.DESCRIPTIONs {display: none;}
.DESCRIPTIONs {
    .center {
        height:3rem !important;
    }
}
.product-image {
    max-width: 5rem;
}
.center {
    height: 5rem;
}
.cant {
    padding: 1rem;
}
@include desktop {
    .cart-container-3 {
        @include grid(3, 2rem);
    }
    .cart-container-2 {
        @include grid(2, 2rem);
    }
    .cart-container-1 {
        @include grid(1, 2rem);
    }
    .cart-container-6 {
        @include grid(6, 1rem);
    }
    .cart-container-7 {
        @include grid(7, 1rem);
    }
    .cart-container-2-0{
        @include grid(2, 0rem);
}
    .product-image {
        max-width: 10rem;
    }
    .center {
        height:15rem !important;
    }
    .cant {
        padding: 0rem;
    }

}


.cart {
    select {
        text-align: center;
        
    }
    .product {
        background-color: rgba($color: #ffffff, $alpha: 0.2);
        margin: 0.5rem;
        padding: 1rem;
    }
    .date {
        max-height: 7rem;
        border-right: 1px solid rgba(202, 202, 202, 0.753) !important;
    }
    .date,
    .stime {
        padding: 1rem !important;
        background-color: $green !important;
            
            
            border: none !important;
            
            text-align: center !important;
            
            display: block !important;
            &:hover {
                background-color: darken($green, 3) !important;
            }
    }
    .date {max-width: 15rem !important;}
        p, label {
            text-align: center;
            font-size: 2.2rem;
        }
        
        .qty,
        .product-removal {
            background-color: $green;
            
            
            border: none;
            
            text-align: center;
            
            display: block;
            &:hover {
                background-color: darken($green, 3);
            }
        }
        .qty,
        .product-removal {
            font-size: 2.2rem;
           
            min-width: 5rem;
        }
        .product-removal {padding: 0.8rem;}
        
        .total-panel {
            margin: 5rem;
            text-align: center;
        }
        .totals {
            max-width: 15rem;
        }
        
        

}

.shopping-cart {
    position: fixed;
    bottom: 5rem;
    right: 2rem;
    z-index: 999;
    span {
        margin-top: 30%;
    }
  }
  
  .shopping-cart a {
    display: block;
    width: 50px;
    height: 50px;
    background-color: #007bff;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    line-height: 50px;
    font-size: 24px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    
  }
  
  .shopping-cart a:hover {
    transform: scale(1.1);
  }

  .principalCartFrame {
    display: none;
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
    width: 100%;
    height: 100%;
  }
  #paypal-button-container {
    display: flex !important;
    justify-content: center !important;
    text-align: -moz-center !important;
}
.center-paypal-buttons {
    width: 100%;
    text-align: -moz-center;
    .buttons-container {
        width: 30rem;
    }
}



  